import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

interface IProps {
    imageRenderedHeight: number;
    isTextOnLeft: boolean;
}

const Default = css<IProps>`
    padding: 24px;
    background-color: var(--back-3);

    br {
        height: 24px;
    }
`;

const Silver = css<IProps>`
    flex-basis: 50%;
    overflow: hidden;
    position: relative;

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 15%;
        /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--back-3) 100%); */
        position: absolute;
        bottom: 0;
        left: 0;
    }

    ${({ imageRenderedHeight }) =>
        imageRenderedHeight &&
        css`
            max-height: ${imageRenderedHeight}px;
        `}

    ${({ isTextOnLeft }) =>
        isTextOnLeft &&
        css`
            order: -1;
        `}
`;

const Gold = css``;

const Platinum = css`
    padding: 38px;

    br {
        height: 38px;
    }
`;

const Diamond = css``;

export const SImageTextSideBySideElementText = styled.div<IProps>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

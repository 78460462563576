import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";

const Default = css`
  background-color: ${(props: any) => props.theme.secondary || "#fff"};
  > div {
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: ${(props: any) => props.theme.primary || "#AAD4CF"} !important;
  }

  height: calc(92vw - 15px);
  width: calc(92vw - 15px);
  max-height: 800px;
  max-width: 1600px;

  img {
    max-height: 800px !important;
    max-width: 1600px !important;
    width: calc(92vw - 15px) !important;
    height: calc(92vw - 15px) !important;
  }
  @media (pointer: coarse) {
    height: calc(92vw);
    width: calc(92vw);
    max-height: 800px;
    max-width: 1600px;

    img {
      max-height: 800px !important;
      max-width: 1600px !important;
      width: calc(92vw) !important;
      height: calc(92vw) !important;
    }
  }
`;

const Silver = css`
  height: calc((92vw - 15px) / 2);
  width: calc(92vw - 15px);

  img {
    width: calc(92vw - 15px) !important;
    height: calc((92vw - 15px) / 2) !important;
  }
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SSliderComponent = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

import React, { FunctionComponent, useState } from "react";
import { getCurrentNavItem, getHeaderNavItemProps, INavigationItem } from "src/common";

import { NavigationContainer, NavigationLinks, NavigationLink, Icon, BurguerIcon } from "./Header.styles";

const Navigation: FunctionComponent<{ items: INavigationItem[]; slug: string }> = ({ items, slug }) => 
{
  const [isMenuActive, toggleMenuActive] = useState(false);
  const currentMainNavItem: any = getCurrentNavItem(items, slug);
  
  return (
    <NavigationContainer active={isMenuActive}>
      <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />

      <NavigationLinks>
        <BurguerIcon onClick={() => toggleMenuActive(!isMenuActive)} />

        <ul>
          {items.map((item, index) => 
          {
            const itemProps = getHeaderNavItemProps(item);
            if(!itemProps) { return; }

            const active =
              !!slug && (`${slug}` === `${itemProps.link}` || currentMainNavItem?.link?.[0]?.slug === `${itemProps.link}`);

            return (
              <NavigationLink key={index} active={active}>
                <a href={itemProps.link}>
                  {item.icon && item.icon[0] && (
                    <Icon
                      src={item.icon?.[0]?.src}
                      alt={"hoverIcon"}
                    />
                  )}

                  {itemProps.title}
                </a>
              </NavigationLink>
            );
          })}
        </ul>
      </NavigationLinks>
    </NavigationContainer>
  );
};

export default Navigation;

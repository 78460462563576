export default {
    black: "#000000",
    white: "#ffffff",
    grey: "#3c3c3b",
    white80: "rgba(255,255,255, 0.8)",
    yellow: "#fed061",
    lightYellow: "#FEE580",
    orange: "#F29315",
    red: "#D30032",
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
    lightGrey: "#f1f1f1",
};

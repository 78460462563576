import * as React from "react";
import { FunctionComponent } from "react";
import * as styles from "./ServiceNumber.style";
import { Context, IContext } from "src/common";

export const ServiceNumber: FunctionComponent<any> = (props) =>
{
    const p = React.useContext<IContext>(Context);

    if(!p.CompanyInformationPublic?.phone)
    {
        return null;
    }

    return (
        <div css={`${styles.wrapper} ${props.css ?? ''} `}>
            <span css={styles.number}> 
                {(!!p.CompanyInformationPublic?.serviceNumber) ? 
                p.CompanyInformationPublic?.serviceNumber 
                : 
                p.CompanyInformationPublic?.phone }
            </span>
            <span>unsere Servicenummer</span>
        </div>
    );
};

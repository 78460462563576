import { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
    span, strong{
        display: inline;
    }
    p{
        display: inline-block;
        width: 100%;
        min-height: 1rem;
    }
    strong {
        p {
            font-weight: 700;
        }
        font-weight: 700;
    }
    svg {
        margin-bottom: -2px;
        path {
            fill: black;
        }
    }
    a {
        cursor: pointer;
        color: unset;
    }
    br {
        display: block;
        content: "";
        margin-top: 0;
        line-height: 0;
    }
    ul li {
        list-style-position: inside;
        list-style-type: disc;
        display: list-item;
    }
    ol li {
        list-style-position: inside;
        display: list-item;
        list-style-type: decimal;
        margin-left: 30px;
    }
    iframe {
        max-width: calc(100vw - 40px);
    }
    h2,
    h3 {
        margin-top: 10px;
        font-size: 15px;
        color: ${props => props.theme.palette.yellow};
        text-transform: uppercase;
        letter-spacing: 0.3px;
    }
    .download {
        p {
            color: ${props => props.theme.palette.yellow};
        }
        p::after {
            content: "";
            display: inline-block;
            background-image: url("/images/sprite-s82c5aa31b4.png");
            background-repeat: no-repeat;
            background-position: -20px -36px;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            transform: translateY(3px);
        }
    }
    width: 100%;
    h1 {
        ${({ theme }) => theme.textStyles.yellow}
    }
    a {
        color: ${({ theme }) => theme.palette.yellow};
    }
    svg {
        path {
            fill: ${({ theme }) => theme.palette.yellow};
        }
    }
    h2,h3,h4{
        &, &>span{
            font-weight: 400;
            font-size: 15px;
            text-transform: uppercase;
            color: ${props => props.theme.palette.yellow};
        }
    }
    strong{
        > *{
            font-weight: 600;
        }
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

const SRichTextGeneral: any = css<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;
export default SRichTextGeneral;

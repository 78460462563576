import { C_1366_Regular } from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  &.text-style-overwrite{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2em;
    max-height: 2.4em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: none;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  &.text-style-overwrite{
    display: block;
    color: var(--front-2);
    font-style: normal;
    max-height: 58.8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${C_1366_Regular}
  }
`;

const Diamond = css``;

export const SBrandDesciption = styled.p<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

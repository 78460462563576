import { IAsset } from "src/common/childTypes";
import { BREAKPOINTS } from "src/common/constants/mediaquerys";

import * as React from "react";

import ImageLoadingPlaceholder from "./imge-loading-placeholder/ImageLoadingPlaceholder";
import SImageWithFocalPoint from "./styles/SImageWithFocalPoint";
import { ISizes } from "../../constants/types";

export interface ImageHeightPerBreakpoint 
{
  bronze: number;
  silver: number;
  gold?: number;
  platinum: number;
}

export interface IImgProps 
{
  lazyload: boolean;
  onLoad?: (img: HTMLImageElement | null) => void;
  sizes: ISizes;
  img: IAsset;
  onClick?: () => void;
  objectFit?: "cover" | "contain";
}

class ImgInner extends React.PureComponent<IImgProps> 
{
  public imgRef = React.createRef<HTMLImageElement>();

  public state = {
    loaded: this.props.lazyload ? false : true,
  };

  public componentDidMount(): void 
  {
    if(this.imgRef.current) 
    {
      if(this.imgRef.current.complete) 
      {
        this.setState({ loaded: true });
        if(this.props.onLoad) 
        {
          this.props.onLoad(this.imgRef.current);
        }
      }
      else 
      {
        this.imgRef.current.onload = () => 
        {
          this.setState({ loaded: true });
          if(this.props.onLoad) 
          {
            this.props.onLoad(this.imgRef.current);
          }
        };
      }
    }
  }

  public componentWillUnmount(): void 
  {
    this.imgRef.current.onload = null;
  }

  public render(): JSX.Element 
  {
    const {
      img, sizes 
    } = this.props;

    if(!img) { return null; }

    const sizesBronzeWidth = typeof sizes?.bronze?.width === "number" ? sizes?.bronze?.width : 360;
    const sizesBronzeHeight = typeof sizes?.bronze?.height === "number" ? sizes?.bronze?.height : 360;
    const sizesSilverWidth = typeof sizes?.silver?.width === "number" ? sizes?.silver?.width : BREAKPOINTS.SILVER;
    const sizesSilverHeight = typeof sizes?.silver?.height === "number" ? sizes?.silver?.height : BREAKPOINTS.SILVER;
    const sizesPlatinumWidth = typeof sizes?.platinum?.width === "number" ? sizes?.platinum?.width : BREAKPOINTS.PLATINUM;
    const sizesPlatinumHeight = typeof sizes?.platinum?.height === "number" ? sizes?.platinum?.height : BREAKPOINTS.PLATINUM;
    const sizesGoldWidth = typeof sizes?.gold?.width === "number" ? sizes?.gold?.width : sizesPlatinumWidth;
    const sizesGoldHeight = typeof sizes?.gold?.height === "number" ? sizes?.gold?.height : sizesPlatinumHeight;

    const actualSrc: string = img.src;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { loaded } = this.state;
    let _src = actualSrc;

    if(!_src.includes(".svg")) 
    {
      _src = _src.includes("?") ? _src + "&" : _src + "?";
    }

    const srcSet = `${ _src + `width=${sizesBronzeWidth}&height=${sizesBronzeHeight}` } ${BREAKPOINTS.SILVER - 1}w, 
    ${ _src + `width=${sizesSilverWidth}&height=${sizesSilverHeight}`} ${BREAKPOINTS.GOLD -1}w, 
    ${ _src + `width=${sizesGoldWidth}&height=${sizesGoldHeight}`} ${BREAKPOINTS.PLATINUM -1}w,
      ${ _src + `width=${sizesPlatinumWidth}&height=${sizesPlatinumHeight}`} ${BREAKPOINTS.DIAMOND - 1}w`;

    return (
      <SImageWithFocalPoint loaded={loaded} objectFit={this.props.objectFit} heightPerBreakpoint={{
        bronze: sizesBronzeHeight, silver: sizesSilverHeight, gold: sizesGoldHeight, platinum: sizesGoldHeight
      }} >
        <img
          srcSet={srcSet}
          src={_src}
          title={img.description}
          alt={img.description}
          ref={this.imgRef}
          sizes={"100vw"}
          loading={this.props.lazyload ? "lazy" : "eager"}
          onClick={this.props.onClick}
        />
        <ImageLoadingPlaceholder isImageLoaded={loaded} img={img} />
      </SImageWithFocalPoint>
    );
  }
}

const ImageWithFocalPoint = React.memo<IImgProps>(({ lazyload= true, ...props }) => 
  <ImgInner {...props} lazyload={lazyload}/>
);

export default ImageWithFocalPoint;

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import styled from "styled-components";

export const SSectionSeparator = styled.hr`
  border: none;
  height: 1px;
  background-color: var(--back-3);
  margin-bottom: 48px;

  @media (${BREAKPOINTS_DM.platinum_1366}) {
    margin-bottom: 76px;
  }
`;

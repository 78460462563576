import { P_1366_Regular, P_360_Regular, P_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
  padding-bottom: 48px;

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;

    .right-side {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .frc {
        &-captcha {
          display: none;
          background-color: var(--back-3);
          margin: 0;
          padding: 5px 10px;
          width: 100%;

          &[data-attached] {
            display: block !important;
          }
        }

        &-container {
          display: flex;
          align-items: center;
        }

        &-icon {
          color: var(--front-2);
          fill: var(--front-2);
          stroke: var(--front-2);
          margin: 0;
          margin-right: 10px;
        }

        &-content {
          button {
            color: var(--front-2);
            border-color: var(--front-2);
          }
        }

        &-banner {
          &,
          a {
            &,
            b {
              color: var(--front-2);
            }
          }
        }
      }
    }

    .left-side {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input,
    textarea {
      width: 100%;
      border: none;
      background-color: var(--back-3);
      ${P_360_Regular}
      
      ${CSSWithOpacityHoverAnimation}
    }
  }
`;

const Silver = css`
  form {
    flex-direction: row;

    input,
    textarea {
      ${P_768_Regular}
    }
  }
`;

const Gold = css``;

const Platinum = css`
  padding-bottom: 76px;

  form {
    gap: 38px;

    .right-side {
      gap: 19px;
    }

    input,
    textarea {
      ${P_1366_Regular}
    }
  }
`;

const Diamond = css``;

export const SForm = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

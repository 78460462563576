import styled from "styled-components";

export const SHalfWidth = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    > div {
        width: 50%;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        > div {
            width: 100%;
        }
    }
`;

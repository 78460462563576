import { IGenAsset } from "src/common/__generated/root/types";

import React, { FunctionComponent, useEffect, useState } from "react";

import { SModalImageLoader } from "./styles/SModalImageLoader";
import { getObjectFitMath } from "../../../../helper";

interface IProps 
{
  isImageLoaded: boolean;
  img: IGenAsset;
  fitMode: "cover" | "contain";
}

let placeholderColor: string;

const ModalImageLoader: FunctionComponent<IProps> = ({ isImageLoaded, img, fitMode }) => 
{
  const [placeholderDimensions, setPlaceholderDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => 
  {
    if(typeof window !== "undefined") 
    {
      const clientWidth = window.innerWidth;
      const clientHeight = window.innerHeight;
      const imgWidth = img?.width;
      const imgHeight = img?.height;

      const { width, height } = getObjectFitMath({ width: clientWidth, height: clientHeight }, { width: imgWidth, height: imgHeight }, fitMode);
      setPlaceholderDimensions({ width, height });
    }
  }, [img, fitMode]);

  if(!img) { return null; }

  const { dominantColor } = img;

  if(dominantColor) 
  {
    if(dominantColor.startsWith("#")) 
    {
      placeholderColor = dominantColor;
    }
    else 
    {
      placeholderColor = `#${dominantColor}`;
    }
  }
  else 
  {
    placeholderColor = "#e3e3e3e3";
  }

  return (
    <SModalImageLoader bgPlaceHolder={placeholderColor} isLoaded={isImageLoaded} width={placeholderDimensions.width} height={placeholderDimensions.height}/>
  );
};

export default ModalImageLoader;

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import { Context } from "../../..";
import { IGenPageBrandOverview } from "../../../types_dealer_gen";
import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import { SBackToOverviewLinkMobile } from "./styles/SBackToOverviewLinkMobile";
import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";

export const BackToOverviewLinkMobile: FunctionComponent = () => {
    const props = useContext(Context);
    const page = props?.PageBrandOverview as IGenPageBrandOverview;

    let href = "/marken";
    let name = "Marken";

    if (page?.nameInNavigation) {
        name = page.nameInNavigation;
    }
    if (page?.slug) {
        href = `/${page.slug}`;
    }

    return (
        <Link href={href} passHref>
            <SBackToOverviewLinkMobile>
                <BrandAreaContainer>
                    <ChevronLeftIcon />
                    <span>{name}</span>
                </BrandAreaContainer>
            </SBackToOverviewLinkMobile>
        </Link>
    );
};

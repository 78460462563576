import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../constants/mediaquerys";

export const wrapper: any = css`
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 12px 8px 10px 8px;
      background-color: ${props => props.theme.palette.black};
      position: fixed;
      z-index: 6;
      bottom: 0;
      height: 30px;
      box-sizing: content-box;
      box-shadow: 2px 2px 5px rgba(50,50,50,.5);
      @media (${BREAKPOINTS_DM.gold_1024})
      {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 30px;
            bottom: initial;
            right: 0;
            top: 130px;
            height: initial;
      }
      @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none), (-ms-high-contrast: active)
      {
          min-height: 115px;
      }
`;

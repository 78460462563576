import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface SDoubleImageElementImgWrapperProps 
{
  variant: "primary" | "secondary";
}

const CSSPrimaryImageBronze = css``;
const CSSPrimaryImageSilver = css`
  width: 74%;
  margin-left: auto;
`;
const CSSPrimaryImagePlat = css``;

const CSSSecondaryImageBronze = css`display:none;`;
const CSSSecondaryImageSilver = css`
  display: block;
  width: 48%;
  border: 24px solid var(--back-1);
  border-bottom: 0;
  z-index: 2;
  position: relative;
  margin-top: calc(-25% + 24px);
`;
const CSSSecondaryImagePlat = css`
  border: 38px solid var(--back-1);
  border-bottom: 0;
`;

const Default = css<SDoubleImageElementImgWrapperProps>`
  display: block;
  padding-bottom: 24px;
  ${({ variant }) => variant === "primary" && CSSPrimaryImageBronze};
  ${({ variant }) => variant === "secondary" && CSSSecondaryImageBronze};
`;

const Silver = css<SDoubleImageElementImgWrapperProps>`
  ${({ variant }) => variant === "primary" && CSSPrimaryImageSilver};
  ${({ variant }) => variant === "secondary" && CSSSecondaryImageSilver};
`;

const Gold = css``;
const Platinum = css<SDoubleImageElementImgWrapperProps>`
  padding-bottom: 38px;
  ${({ variant }) => variant === "primary" && CSSPrimaryImagePlat};
  ${({ variant }) => variant === "secondary" && CSSSecondaryImagePlat};
`;
const Diamond = css``;

export const SDoubleImageElementImgWrapper = styled.div<SDoubleImageElementImgWrapperProps>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

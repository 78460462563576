import { IGenTripleImageElementComp } from "src/common/__generated/root/types";

import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { STripleImageElement } from "./style/STripleImageElement";
import { FancyImageWithModal } from "../../../BaseComponents/FancyImageWithModal/FancyImageWithModal";

export const TripleImageElement: FunctionComponent<IGenTripleImageElementComp> = ({ images }) => {
    return images ? (
        <BrandAreaContainer>
            <STripleImageElement>
                <div className="left">
                    {images[0] && (
                        <FancyImageWithModal
                            img={images[0]}
                            responsiveAspectRatio={{
                                bronze: 312 / 468,
                                silver: 348 / 486,
                                platinum: 588 / 822,
                            }}
                            responsiveFactor={{ silver: 0.5 }}
                            responsiveMaxWidth={{ platinum: 1366 * 0.5 }}
                        />
                    )}
                </div>
                <div className="right">
                    {images.slice(1).map((image, index) => {
                        return (
                            <React.Fragment key={index}>
                                <FancyImageWithModal
                                    img={image}
                                    responsiveAspectRatio={{
                                        bronze: 348 / 232,
                                        silver: 348 / 232,
                                        platinum: 588 / 392,
                                    }}
                                    responsiveFactor={{ silver: 0.5 }}
                                    responsiveMaxWidth={{ platinum: 1366 * 0.5 }}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            </STripleImageElement>
        </BrandAreaContainer>
    ) : null;
};

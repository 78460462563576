import { H2_1366_Regular, H2_360_Regular, H2_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
  width: 100%;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  color: var(--back-1);
  transition: background-color 0.3s ease-in;

  ${H2_360_Regular}

  &:hover {
    background-color: var(--front-2);
  }
`;

const Silver = css`
${H2_768_Regular}
`;

const Gold = css``;

const Platinum = css`
${H2_1366_Regular}
`;

const Diamond = css``;

export const SDropdownItem = styled.p`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  margin-bottom: 48px;
`;

const Silver = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  `;

const Gold = css``;

const Platinum = css`
  gap: 38px;
  margin-bottom: 76px;
`;

const Diamond = css``;

export const SBrandPageHeader = styled.div<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

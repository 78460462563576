import * as React from "react";
import { FunctionComponent } from "react";

import SBurgerMenu from "./Styles/SBurgerMenu";

interface IProps 
{
    isInSidebar: boolean;
    onClick: () => void;
}

export const BurgerMenu: FunctionComponent<IProps> = props =>
    <SBurgerMenu inSidebar={props.isInSidebar} onClick={props.onClick}/>;

import React, { FunctionComponent } from "react";

import SRow from "./Styles/SRow";
import SRowOuter from "./Styles/SRowOuter";

const Row: FunctionComponent<{ fullWidth: boolean }> = (props) => 
{
  if(props.fullWidth) 
  {
    return <SRow>{props.children}</SRow>;
  }
  return (
    <SRowOuter>
      <SRow>{props.children}</SRow>
    </SRowOuter>
  );
};

export default Row;

import {
  H1_360_Bold,
  H2_1366_Regular,
  H2_360_Regular,
  H2_768_Regular,
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface IDropdownButtonProps 
{
  hasNameInNavigation: boolean;
  isOpen: boolean;
}

const Default = css<IDropdownButtonProps>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  transition: background 0.3s ease-in, color 0.3s ease-in;
  background: var(--back-3);

${({ hasNameInNavigation }) => !hasNameInNavigation ?
    css`
  background: var(--back-3);
  
  span.title-overwrite {
    ${H2_360_Regular}
  }
` : css`
  span.title-overwrite {
    ${H1_360_Bold}
  }
`
}

span.title-overwrite {
    width: 100%;
    padding: 12px;
    background: var(--back-3);
    color: var(--front-3);
    transition: background 0.3s ease-in, color 0.3s ease-in;
  }

  ${({ hasNameInNavigation, isOpen }) => hasNameInNavigation && !isOpen &&
    css`
      background: var(--back-2);

      span.title-overwrite {
        background: linear-gradient(90deg, var(--back-1) 63.84%, var(--back-3) 100%);
        color: var(--front-2);
      }
    `
}

  ${({ isOpen }) => isOpen
    && css`
          background: var(--front-2);

          span.title-overwrite {
            color: var(--back-1);
            background: var(--front-2);
          }
        `
}
`;

const Silver = css<IDropdownButtonProps>`
  ${({ hasNameInNavigation, isOpen }) => hasNameInNavigation && !isOpen &&
    css`
      background: var(--back-3);
      span.title-overwrite {
        background: var(--back-3);
        color: var(--front-3);
      }
    `
}

  span.title-overwrite {
    ${H2_768_Regular}
  }
`;

const Gold = css``;

const Platinum = css`
  span.title-overwrite {
    ${H2_1366_Regular}
  }
`;

const Diamond = css``;

export const SDropdownButton = styled.h2`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

import React, { FunctionComponent } from "react";
import { CommonPageBrandOverviewMainContent } from "src/common/brand-area/PageBrandOverview/CommonPageBrandOverviewMainContent";

const PageBrandOverviewT0: FunctionComponent = () => {
  return (
    <>
      <CommonPageBrandOverviewMainContent />
    </>
  );
};

PageBrandOverviewT0.displayName = "PageBrandOverviewT0";
export { PageBrandOverviewT0 };

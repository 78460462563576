import { RichText } from "src/common";
import {
  IGenTextElementComp,
} from "src/common/__generated/root/types";

import React, { FunctionComponent,  } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { STextElement } from "./styles/STextElement";
import { STextElementTextWrapper } from "./styles/STextElementTextWrapper";

const TextElement: FunctionComponent<
  IGenTextElementComp & { width?: string }
> = ({ text, width }) => {
  if (!text) return null;

  return (
    <BrandAreaContainer>
      <STextElement width={width} isRichtextComponent={!!text}>
        <STextElementTextWrapper>
          {text && (
            <div className="richText">
              <RichText iframeContainer fragment textContent={text} />
            </div>
          )}
        </STextElementTextWrapper>
      </STextElement>
    </BrandAreaContainer>
  );
};

export default TextElement;

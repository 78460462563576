import { Context } from "src/common";
import ChevronDownIcon from "src/common/brand-area/icons/ChevronDownIcon";

import Link from "next/link";
import React, { FunctionComponent, useState, useContext } from "react";

import { SBrandsDropdown } from "./styles/SBrandsDropdown";
import { SBrandsDropdownIconWrapper } from "./styles/SBrandsDropdownIconWrapper";
import { SDropdownButton } from "./styles/SDropdownButton";
import { SDropdownContent } from "./styles/SDropdownContent";
import { SDropdownItem } from "./styles/SDropdownItem";
import { useClickOutside } from "webrix/hooks";
import { getBrandNavigationItem } from "../../../utils/getBrandNavigationItem";

export const BrandsDropdown: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const c = useContext(Context);
    const brandNavItem = c.PrimaryNavigation && getBrandNavigationItem(c.PrimaryNavigation);
    const subitems = brandNavItem?.subitems;
    const currentItem = subitems?.find((item) => item?.slug === c.slug);
    const hasNameInNavigation = !!currentItem?.nameInNavigation;

    const handleOnMouseDownCapture = useClickOutside(() => {
        // Do something when clicking outside the target element
        setIsOpen(false);
    });

    if (!brandNavItem) {
        return null;
    }

    return (
        <>
            <SBrandsDropdown onMouseDownCapture={handleOnMouseDownCapture}>
                <SDropdownButton
                    className="text-style-overwrite"
                    onClick={() => setIsOpen(!isOpen)}
                    hasNameInNavigation={hasNameInNavigation}
                    isOpen={isOpen}
                >
                    <span className="title-overwrite">
                        {currentItem && hasNameInNavigation ? currentItem?.nameInNavigation : "Unsere Marken ..."}
                    </span>
                    <SBrandsDropdownIconWrapper isOpen={isOpen} hasNameInNavigation={hasNameInNavigation}>
                        <ChevronDownIcon />
                    </SBrandsDropdownIconWrapper>
                </SDropdownButton>
                <SDropdownContent isOpen={isOpen} >
                    {subitems &&
                        subitems.length > 0 &&
                        subitems.map(
                            (brand, brandIndex) =>
                                brand && (
                                    <Link key={brandIndex} href={`/${brand.slug}`} passHref>
                                        <a>
                                            <SDropdownItem
                                                onClick={() => setIsOpen(false)}
                                                className="text-style-overwrite"
                                            >
                                                {brand.nameInNavigation}
                                            </SDropdownItem>
                                        </a>
                                    </Link>
                                )
                        )}
                </SDropdownContent>
            </SBrandsDropdown>
            {/* this div to avoid float effecting also the element after not just before */}
            {/* <div style={{ opacity: "0", border: "1px solid red" }}>.</div> */}
        </>
    );
};

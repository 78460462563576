import React, { FunctionComponent } from "react";
import { SBrandHeaderLogo } from "./styles/SBrandHeaderLogo";
import { IGenAsset } from "src/common/types_dealer_gen";
import { FancyImage } from "src/common/BaseComponents/FancyImage/FancyImage";
import { SBrandHeaderLogoWrapper } from "./styles/SBrandHeaderLogoWrapper";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";

const BrandHeaderLogo: FunctionComponent<{ img: IGenAsset, asHtmlTag?:string, href?:string }> = ({ img, asHtmlTag="div", href }) => {
    const brandAreaMaxWidth = useBrandAreaMaxWidth()
    if (!img || !img.src) {
        return null;
    }

    return (
        <SBrandHeaderLogoWrapper as={asHtmlTag as any} href={href}>
            <SBrandHeaderLogo>
                <FancyImage
                    lazyload={false}
                    img={img}
                    responsiveFactor={{ bronze: 1, silver: 0.25 }}
                    responsiveMaxWidth={{ platinum: brandAreaMaxWidth / 4 }}
                />
            </SBrandHeaderLogo>
        </SBrandHeaderLogoWrapper>
    );
};

export default BrandHeaderLogo;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

export const CSSItem = css`
    flex: 1;
`;

const Default = css`
    padding-bottom: 48px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    padding-bottom: 76px;
`;

const Diamond = css``;

export const SBrandTopicTeaserElement = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import {
    C_1366_Bold,
    C_768_Bold,
    H2_1366_Regular,
    H2_360_Regular,
    H2_768_Regular,
    C_360_Bold,
} from "src/common/brand-area/constantsOverwrites/typography";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 2px;

    > .mark {
        ${H2_360_Regular}
        color: var(--front-2);
    }

    > .text {
        ${C_360_Bold}
        color: var(--primary-100);
    }

    ${CSSWithOpacityHoverAnimation};
`;

const Silver = css`
    > .mark {
        ${H2_768_Regular}
        margin-top: 2px;
    }

    > .text {
        ${C_768_Bold}
    }
`;

const Gold = css``;

const Platinum = css`
    margin-top: 24px;
    gap: 4px;

    > .mark {
        ${H2_1366_Regular}
    }

    > .text {
        ${C_1366_Bold}
    }
`;

const Diamond = css``;

export const STextElementCollapseButton = styled.button`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

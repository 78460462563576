import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css<{isRichtextComponent: boolean; width?: string}>`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;

${({ isRichtextComponent }) => isRichtextComponent && css`
padding-bottom: 48px;
`}

`;

const Silver = css<{width?: string}>`
${({ width }) => width && `max-width: ${width};`}
gap: 12px;
`;

const Gold = css`
`;

const Platinum = css<{isRichtextComponent: boolean}>`
gap: 38px;

${({ isRichtextComponent }) => isRichtextComponent && css`
padding-bottom: 76px;
`}
`;

const Diamond = css``;

export const STwoColTextBlock = styled.div<{isRichtextComponent: boolean; width?: string}>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

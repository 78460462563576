import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface IImgWrapperProps 
{
  isImageLoaded?: boolean;
}

const Default = css<IImgWrapperProps>`
  margin-top: 24px;
  background: var(--back-3);
  margin-bottom: 26px;
  position: relative;
  width: 100%;
  padding-top: 140%;

  &> div {
    position: absolute;
    top:0;
    left:0;
    img {
      border: 48px solid var(--back-3);
    }
  }
`;

const Silver = css`
  margin-top: 0px;
  width: 50%;
  padding-top: 70%;
`;

const Gold = css``;

const Platinum = css<IImgWrapperProps>`
  margin-bottom: 38px;

  & > div {
    img {
      border: 76px solid var(--back-3);
    }
  }
`;

const Diamond = css``;

export const SDownloadElementImgWrapper = styled.div<IImgWrapperProps>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

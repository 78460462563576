// @ts-nocheck
import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const FooterWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth}px;
  width: 100%;
  padding: 20px 10px 32px;
  margin: 0 auto 30px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 20px 0 32px 0;
    margin: auto;
`;

export const wrapper: any = css`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 10px auto 130px;
    padding: 0 10px;
`;

export const LinksWrapper: any = styled.div`
    column-count: 4;
    column-gap: 20px;
    margin-bottom: 30px;
    display: none;

    p {
      margin-bottom: 3px;
    }
    a {
      font-size: 12px;

      color: ${props => props.theme.palette.black50};
      &:hover {
        color: ${props => props.theme.palette.green};
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
      border-bottom: 1px solid #505050;
      border-top: 1px solid #505050;
      margin-bottom: 10.5px;
      padding: 5.25px 10.5px;
      display: block;
    }
`;

export const Link: any = styled.a<{ isBold: boolean }>`
  display: block;
  color: #505050;
  line-height: 1.2rem;
  ${({ isBold }) => isBold && 'font-weight: bold;'}
`;

export const AddressWrapper: any = styled.div`
    margin-bottom: 5px;
    p, div {
      line-height: 21px;
      font-size: 13px;
      font-weight: 300;
    }

    a {
      color: ${props => props.theme.palette.green};
      &:hover {
        color: ${props => props.theme.palette.green};
      }
    }

    & > .managingDirectorWrapper {
      display: flex;
      align-items: center;
      p {
        margin-top: 0;
      }
    }

    & > .addressLinksWrapper {
      & > span {
        margin: 0 4px;
      }
    }
`;

export const ShareOnSocialMediaWrapper: any = styled.div`
    display: flex;
    a {
      display: flex;
      align-items: center;
      margin-right: 12px;
      padding: 8px;
      height: 35px;
      width: 85px;
      span {
        margin-left: 10px;
        color: white;
        display: none;
      }
      &:first-child {
        background-color: #3b5998;
        &:hover {
          background-color: #486dbc;
        }
      }
      &:nth-child(2) {
        background-color: #55acee;
        &:hover {
          background-color: #78c2ff;
        }
      }
      &:nth-child(3) {
        background-color: #bd081c;
        &:hover {
          background-color: #f10c23;
        }
      }
      &:nth-child(4) {
        background-color: #999;
        &:hover {
          background-color: #c1c1c1;
        }
      }
      svg {
        height: 100%;
        fill: white;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
        a {
            width: 180px;
            span {
              display: block;
            }
        }
    }
`;

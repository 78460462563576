import { css } from "styled-components";

import colors from "../../../constants/colors";
import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const wrapper: any = css`
    max-width: ${({ theme }) => theme.maxWidth}px;
    width: 100%;
    margin: 10px auto 130px;
    padding: 0 10px;
`;

export const lineLogo: any = css`
    img{
      width: auto;
      object-fit: contain;
      height: 50px;
    }
`;

export const addressWrapper: any = css`
    margin-bottom: 20px;
    text-align: right;
    p, div {
      margin-top: 9px;
      font-size: 12px;
      line-height: 10px;
    }
    a {
      &:hover {
        color: ${colors.yellow};
      }
    }
`;

export const companyName: any = css`
    font-weight: 700;
`;

export const shareOnSocialMediaWrapper: any = css`
    display: flex;
    justify-content: flex-end;
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 12px;
      padding: 8px;
      height: 35px;
      width: 85px;
      text-transform: uppercase;
      span {
        margin-left: 10px;
        color: white;
        display: none;
      }
      &:first-child {
        background-color: #3b5998;
        &:hover {
          background-color: #486dbc;
        }
      }
      &:nth-child(2) {
        background-color: #55acee;
        &:hover {
          background-color: #78c2ff;
        }
      }
      &:nth-child(3) {
        background-color: #bd081c;
        &:hover {
          background-color: #f10c23;
        }
      }
      &:nth-child(4) {
        background-color: #999;
        margin-right: 0;
        &:hover {
          background-color: #c1c1c1;
        }
      }
      svg {
        height: 100%;
        fill: white;
      }
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
        a {
            width: 150px;
            height: 30px;
            span {
              display: block;
            }
        }
    }
`;

import { IGenDoubleImageElementComp } from "src/common/__generated/root/types";
import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SDoubleImageElement } from "./style/SDoubleImageElement";
import { SDoubleImageElementImgWrapper } from "./style/SDoubleImageElementImgWrapper";
import { FancyImageWithModal } from "../../../BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";

export const DoubleImageElement: FunctionComponent<IGenDoubleImageElementComp> = ({ images }) => {
    // the big image
    const primaryImage = images[0];
    // the small image
    const secondaryImage = images[1];
    return (
        <BrandAreaContainer>
            <SDoubleImageElement>
                {primaryImage && (
                    <SDoubleImageElementImgWrapper variant={"primary"}>
                        <FancyImageWithModal
                            img={primaryImage}
                            responsiveAspectRatio={{
                                bronze: 312 / 468,
                                silver: 1,
                            }}
                            responsiveFactor={{ silver: 0.9 }}
                            responsiveMaxWidth={{ platinum: useBrandAreaMaxWidth() * 0.9 }}
                        />
                    </SDoubleImageElementImgWrapper>
                )}
                {secondaryImage && (
                    <SDoubleImageElementImgWrapper variant={"secondary"}>
                        <FancyImageWithModal
                            img={secondaryImage}
                            responsiveAspectRatio={{
                                bronze: 312 / 468,
                                silver: 348 / 232,
                                platinum: 588 / 392,
                            }}
                            responsiveFactor={{ silver: 0.55, platinum: 0.55 }}
                            responsiveMaxWidth={{ platinum: useBrandAreaMaxWidth() * 0.55 }}
                        />
                    </SDoubleImageElementImgWrapper>
                )}
            </SDoubleImageElement>
        </BrandAreaContainer>
    );
};

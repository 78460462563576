import styled, { css } from "styled-components";

export const Wrapper: any = styled.div`
  align-items: center;
  background-color: ${props => props.theme.palette.green};
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  padding: 12px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    display: inline: block;

    &:first-child {
      font-size: 29px;
      line-height: .85;
      margin-right: 15px;
    }

    &:last-child {
      font-size: 15px;
      text-transform: uppercase;
      color: white;
      white-space: nowrap;
    }
  }
`;
import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css<any>`
  display: none;
`;

const Silver = css<any>`
  display: block;
  padding-bottom: 48px;
  span {
    width: 100%;
  }
`;

const Gold = css``;

const Platinum = css<any>`
  padding-bottom: 76px;
`;

const Diamond = css``;

export const SSubtopicLinksWrapper = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

import {
  H2_1366_Bold, H2_1366_Regular, H2_360_Bold, H2_360_Regular, H2_768_Bold, H2_768_Regular, P_1366_Bold, P_1366_Regular, P_360_Bold, P_360_Regular, P_768_Bold, P_768_Regular 
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
    button {
    width: 100%;
    height: 48px;
    background: var(--primary-100);

    /* Typography */
    color: var(--back-1);
    text-align: center;
    ${P_360_Bold}
  }
  h2.headline {
    color: var(--primary-100);
    padding-bottom: 24px;
    align-self: flex-start;
    ${H2_360_Bold}
  }
  h3.subtitle {
    color: var(--front-2);
    margin-bottom: 24px;
    ${H2_360_Regular}
  }

  p {
    color: var(--front-2);
    ${P_360_Regular}
  }
  margin-bottom: 48px;
`;

const Silver = css`
  .layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }

  h2.headline {
    ${H2_768_Bold}
  }
  h3.subtitle {
    margin-bottom: 38px;
    ${H2_768_Regular}
  }
  p {
    ${P_768_Regular}
  }
  .text {
    width: 50%;
  }
  button {
    display: block;
    width: calc(50% - 6px);
    margin-left: auto;
    ${P_768_Bold}
  }
`;

const Gold = css``;

const Platinum = css`
  gap: 38px;
  h2.headline {
    padding-bottom: 38px;
    ${H2_1366_Bold}
  }

  h3.subtitle {
    ${H2_1366_Regular}
  }
  .text {
    p {
      ${P_1366_Regular}
    }
  }
  button {
    ${P_1366_Bold}
  }
  margin-bottom: 76px;
`;

const Diamond = css``;

export const SDownloadElement = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

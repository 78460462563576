import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    width: 100%;
    border: 1px solid var(--back-3, #f9f9f9);
    padding: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--special-logobg, #fefefe);
    img {
        object-fit: contain;
    }
`;

const Silver = css`
    padding: 24px;
`;

const Gold = css``;

const Platinum = css`
    padding: 38px;
`;

const Diamond = css``;

export const SBrandHeaderLogo = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

import { Context, Img } from "src/common";
import { IGenPageBrandMain } from "src/common/__generated/root/types";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SBrandPageHeader } from "./styles/SBrandPageHeader";
import { SBrandPageHeaderContentWrapper } from "./styles/SBrandPageHeaderContentWrapper";
import { SBrandPageHeaderLink } from "./styles/SBrandPageHeaderLink";
import { SBrandPageHeaderNavName } from "./styles/SBrandPageHeaderNavName";
import BrandHeaderLogo from "../BrandHeaderLogo/BrandHeaderLogo";
import ChevronRightIcon from "../../icons/ChevronRightIcon";

interface IHeader {
    variant?: "main-topic" | "sub-topic";
}

export const BrandPageHeader: FunctionComponent<IHeader> = ({ variant = "main-topic" }) => {
    const c = useContext(Context);
    const page = c?.PageBrand as unknown as IGenPageBrandMain;
    const brandSubtopicPages = page?.subtopics || [];

    const logo = page?.brandLogo;
    const nameInNavigation = page?.nameInNavigation;

    return (
        <BrandAreaContainer>
            <SBrandPageHeader>
                {logo && logo.src && <BrandHeaderLogo img={logo} />}
                <SBrandPageHeaderContentWrapper>
                    {/* {nameInNavigation && variant === "main-topic" && (
                        <SBrandPageHeaderNavName>{nameInNavigation}</SBrandPageHeaderNavName>
                    )}
                    {nameInNavigation && variant === "sub-topic" && (
                        <Link href={`/${page.slug}`}>
                            <a>
                                <SBrandPageHeaderNavName hasOnClick>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    {nameInNavigation}
                                </SBrandPageHeaderNavName>
                            </a>
                        </Link>
                    )} */}
                    {brandSubtopicPages && brandSubtopicPages.length > 0 && (
                        <div className="subtopic-links">
                            {brandSubtopicPages &&
                                brandSubtopicPages.map((subtopic, index) => (
                                    <Link href={`/${page.slug}/${subtopic.slug}`} key={index} passHref>
                                        <SBrandPageHeaderLink key={index}>
                                            <ChevronRightIcon />&nbsp;<span>{subtopic?.nameInNavigation}</span>
                                        </SBrandPageHeaderLink>
                                    </Link>
                                ))}
                        </div>
                    )}
                </SBrandPageHeaderContentWrapper>
            </SBrandPageHeader>
        </BrandAreaContainer>
    );
};

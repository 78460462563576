import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  margin-bottom:24px;
  position: relative;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
  margin-bottom:38px;
`;

const Diamond = css``;

export const SDoubleImageElement = styled.div<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

import { Context, Img } from "src/common";
import { IGenPageBrandMain, IGenPageBrandTopic } from "src/common/__generated/root/types";

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SSubtopicHeader } from "./styles/SSubtopicHeader";
import BrandHeaderLogo from "../BrandHeaderLogo/BrandHeaderLogo";

export const SubtopicHeader: FunctionComponent = () => {
    const c = useContext(Context);
    const topic = c?.PageBrandTopic as IGenPageBrandTopic;
    const page = c?.PageBrand as unknown as IGenPageBrandMain;
    const logo = page?.brandLogo;

    return (
        <BrandAreaContainer>
            <SSubtopicHeader>
                <Link href={`/${page.slug}`} passHref>
                    <BrandHeaderLogo img={logo} asHtmlTag={"a"} />
                </Link>
                <div className="content">
                    <div className="back-to-brand">
                        {/* <Link href={`/${page.slug}`} passHref>
                            <a>
                                <span className="text-style-overwrite">
                                    <ChevronLeftIcon />
                                    {page.nameInNavigation}
                                </span>
                            </a>
                        </Link> */}
                    </div>

                    <div className="subtopic-name">
                        <p>{topic.nameInNavigation}</p>
                    </div>
                </div>
            </SSubtopicHeader>
        </BrandAreaContainer>
    );
};

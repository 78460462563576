import { IGenAsset } from "src/common/__generated/root/types";
import ImageWithFocalPoint from "src/common/BaseComponents/img-with-focal-point/ImageWithFocalPoint";
import ModalImageLoader from "src/common/brand-area/components/full-screen-image-focal-point/modal-imge-loader/ModalImageLoader";

import React, { FC, FunctionComponent, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import * as styles from "./FullScreenImageWithFocalPoint.style";
import { ISizes } from "../../../constants/types";
if(typeof window === "undefined") { React.useLayoutEffect = React.useEffect; }

interface IProps
{
  img: IGenAsset;
  lazyload?: boolean;
  noWrapper?: boolean;
  id?: string;
  sizes: ISizes;
  objectFit?: "cover" | "contain";
}

const Modal: FC = (props) => 
{
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => 
  {
    let el;

    if(typeof window !== "undefined") 
    {
      el = document.createElement("div");
      const rootContainer = document.querySelector("#__next");
      rootContainer.appendChild(el);
      container.current = el;
    }

    return () => el?.remove();
  }, []);

  return container.current
    ? createPortal(props.children, container.current)
    : null;
};

const Inner: FunctionComponent<IProps> = (props) => 
{
  const [isActive, setIsActive] = useState(false);
  const [isModalImgLoaded, setIsModalImgLoaded] = useState(false);

  if(!props.img) { return null; }

  return (
    <>
      <ImageWithFocalPoint
        img={props.img}
        onClick={() => setIsActive(true)}
        lazyload={props.lazyload}
        sizes={props.sizes}
        objectFit={props.objectFit}
      />
      <Modal>
        <div
          css={`${styles.wrapper} ${isActive ? styles.wrapperActive : styles.wrapper}`}
          onClick={() => setIsActive(false)}
        >
          <div
            css={styles.fullScreenWrapper}
            style={isActive ? { display: "block" } : { display: "none" }}
            data-fullscreen-image-wrapper>
            <div>
              { isActive && <div>
                <img
                  src={props.img.src}
                  css={styles.imgFullScreen({ isLoaded: isModalImgLoaded })}
                  alt={props.img.description}
                  loading="lazy"
                  onLoad={() => setIsModalImgLoaded(true) }
                /> 
                <ModalImageLoader fitMode="contain" isImageLoaded={isModalImgLoaded} img={props.img}/>
              </div> }
              { isModalImgLoaded && <div className="fullscreen-caption">
                <svg
                  data-close
                  css={styles.svg}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                </svg>
              </div> }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const FullScreenImageWithFocalPoint: FunctionComponent<IProps> = (props) => 
{
  if(props.noWrapper) { return <Inner key={props.id} {...props} />; }

  return (
    <div css={!props.noWrapper ? styles.img : null} data-fullscreen-image>
      <Inner key={props.id} {...props} />
    </div>
  );
};
export default FullScreenImageWithFocalPoint;

import { css } from "styled-components";

export const wrapper: any = css`
    display: flex;
    flex-direction: column;
    position: relative;
    span {
      color: black;
    }
`;

export const spacer: any = css`
    display: block;
    height: 20px;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
`;

export const dropDown: any = css`
    margin: 0 6px;
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: 100%;
    box-shadow: 2px 2px 5px rgba(50,50,50,.5);
    a {
         background-color: white;
         color: black;
         font-size: 12px;
         white-space: nowrap;
         border-radius: 0;
         padding: 8px;
         &:hover {
            background-color: white;
            color: ${props => props.theme.palette.yellow};
         }
    }
`;

export const dropDownWrapper: any = css`
    position: absolute;
    z-index: 6;
    top: 0;
`;

export const active: any = css`
    color: ${props => props.theme.palette.yellow} !important;
`;

export const inActive: any = css`
    display: none;
`;

export const link: any = css`
    font-weight: 700;
    white-space: nowrap;
    font-size: 13px;
    color: #1D1D1B;
    margin: 0 6px;
    &:hover{
      color: ${props => props.theme.palette.yellow};
    }
`;

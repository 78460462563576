import { IGenAsset } from "src/common/__generated/root/types";

import React, { FunctionComponent } from "react";

import { SImageLoadingPlaceholder } from "./styles/SImageLoadingPlaceholder";

interface IProps 
{
  isImageLoaded: boolean;
  img: IGenAsset;
}

let placeholderColor: string;

const ImageLoadingPlaceholder: FunctionComponent<IProps> = ({ isImageLoaded, img }) => 
{
  const { dominantColor } = img;

  if(dominantColor) 
  {
    if(dominantColor.startsWith("#")) 
    {
      placeholderColor = dominantColor;
    }
    else 
    {
      placeholderColor = `#${dominantColor}`;
    }
  }
  else 
  {
    placeholderColor = "#e3e3e3e3";
  }

  return (
    <SImageLoadingPlaceholder bgPlaceHolder={placeholderColor} isLoaded={isImageLoaded}/>
  );
};

export default ImageLoadingPlaceholder;

import React, { useContext } from "react";
import { FunctionComponent } from "react";
import * as styles from "./Banner.style";
import { BannerLink } from "./BannerLink/BannerLink";
import { Context } from "src/common";
import { addHttpsToLinks } from "../../../../common/utils/addHttpsToLinks";

export const Banner: FunctionComponent = () => {
    const props = useContext(Context);
    const { email, facebook } = props?.CompanyInformationPublic;
    const contactSlug = props.PageContact?.slug;
    return (
        <div css={styles.wrapper}>
            {contactSlug && <BannerLink href={`/${contactSlug}`} className={"phone"} />}
            {email && <BannerLink href={"mailto:" + email} className={"at"} />}
            {contactSlug && <BannerLink href={`/${contactSlug}`} className={"way"} />}
            {facebook && <BannerLink href={addHttpsToLinks(facebook)} className={"fb"} />}
        </div>
    );
};
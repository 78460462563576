import { css } from "styled-components";

import { BREAKPOINTS_DM } from "../../../constants/mediaquerys";

export const navContainerT4: any = css`
    margin-top: 10px;
    width: 100%;
    height: 70px;
    @media (${BREAKPOINTS_DM.silver_768})
    {
        height: 95px;
    }
`;

export const companyLogoT4: any = css`
    position: absolute;
    left: 0;
    top: 10px;
    padding-left: 20px;
    width: 100%;
    max-width: 380px;
    padding-right: 70px;
    max-height: 120px;
    object-fit: contain;
    @media (${BREAKPOINTS_DM.bronze_375})
    {
        top: 0;
    }

    @media (${BREAKPOINTS_DM.silver_768})
    {
        padding-right: 0;
    }

    @media (${BREAKPOINTS_DM.gold_1024})
    {
        padding-left: 0;
    }
`;

export const menuDesktopT4: any = css`
    height: 100%;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
          display: flex;
          max-width: ${({ theme }) => theme.maxWidth}px;
          width: 100%;
          margin: auto;
          position: relative;
          justify-content: flex-end;
          z-index: 7;
    }
`;

export const ulMenuDesktopT4: any = css`
    display: none;
    @media (${BREAKPOINTS_DM.gold_1024})
    {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

export const menuResponsiveT4: any = css`
      position: fixed;
      z-index: 9999;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transition: transform ease .5s;
      overflow-y: auto;
      display: flex;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          display: none;
      }
`;

export const ulMenuResponsiveT4: any = css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 80px;
      width: 70%;
      background-color: ${props => props.theme.palette.yellow};
      box-shadow: -2px -2px 5px rgba(50,50,50,.5);
      min-height: 100%;
      box-sizing: border-box;
      margin-bottom: auto;
      @media (${BREAKPOINTS_DM.gold_1024})
      {
          display: none;
      }
`;

export const backDropT4: any = css`
      width: 30%;
      height: 100%;
      opacity: 0;
`;

export const openSubMenuButtonT4: any = css`
    color: white;
    display: inline-block;
    margin-left: 7px;
`;

export const svgT4: any = css`
    fill: red;
    height: 13px;
    width: 13px;
`;

export const activeT4: any = css`
    color: ${props => props.theme.palette.yellow} !important;
`;

// Mapped navitem Style

export const linkT4: any = css`
    font-weight: 700;
    white-space: nowrap;
    font-size: 13px;
    color: ${props => props.theme.palette.grey}
    margin: 0 6px;
    &:hover{
      color: ${props => props.theme.palette.yellow};
    }
`;

export const navItemResponsiveT4: any = css`
    background-color: black;
    width: 99%;
    margin-bottom: 2px;
    border-radius: 10px 0 0 10px;
    border-bottom: 1px solid white;
    position: relative;
    &:hover {
    background-color: rgb(57,57,57);
    }
`;

export const linkResponsiveT4: any = css`
      padding: 22px;
      display: block;
      color: white;
      font-weight: 400;
      font-size: 18px;
      z-index: 9999999;
`;

export const subNavItemResponsiveT4: any = css`
   background-color: black;
   width: 100%;
   margin-bottom: 2px;
   border-radius: 10px 0 0 10px;
   color: white;
   padding: 10px;
   display: block;
   box-sizing: border-box;
   &:hover {
    background-color: rgb(57,57,57);;
   }
`;

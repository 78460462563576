import React, { FunctionComponent } from "react";

import { IGenBlockImageGridComp } from "../../../__generated/root/types";
import Gallery from "../../BaseComponentsOverwrites/Gallery/Gallery";
import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SBlockImageGrid } from "./styles/SBlockImageGrid";
import { FancyImageWithModal } from "../../../BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";

export const BlockImageGrid: FunctionComponent<IGenBlockImageGridComp> = ({ images }) => {
    const brandAreaMaxWidth = useBrandAreaMaxWidth();

    if (images.length < 0) {
        return null;
    }

    return (
        <BrandAreaContainer>
            <SBlockImageGrid>
                <Gallery fullWidth bronze={2}>
                    {images?.map((image) => {
                        if (!image || !image?.id) {
                            return null;
                        }
                        return (
                            <FancyImageWithModal
                                key={image.id}
                                img={image}
                                responsiveAspectRatio={{
                                    bronze: 144 / 144,
                                    silver: 348 / 232,
                                    platinum: 588 / 392,
                                }}
                                responsiveFactor={{ silver: 0.55 }}
                                responsiveMaxWidth={{ platinum: brandAreaMaxWidth * 0.55 }}
                            />
                        );
                    })}
                </Gallery>
            </SBlockImageGrid>
        </BrandAreaContainer>
    );
};

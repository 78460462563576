import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import { FlattenSimpleInterpolation, css, keyframes } from "styled-components";

export const wrapper = css`
  background-color: rgba(0, 0, 0, 0.81);
  position: fixed;
  z-index: 9999999999999;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.6s ease, height 0s linear 0.6s, width 0s linear 0.6s;
  pointer-events: none;
  padding: 90px 0 180px 0;
`;

export const wrapperActive: any = css`
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.6s ease, height 0s linear 0s, width 0s linear 0s;
`;

export const fullScreenWrapper: any = css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > div:nth-child(1) {
      width: 100%;
      height: 100%;
    }
    > div:nth-child(2) {
      width: calc(100vw - 40px);
      @media (${BREAKPOINTS_DM.gold_1024}) {
        width: unset;
      }
    }
  }
  padding: 0 20px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0;
  }
  .fullscreen-caption {
    position: relative;
    svg {
      position: absolute;
      top: 10px;
      right: 50%;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: 0 20px;
      svg {
        right: 50%;
      }
    }
  }
  svg {
    cursor: pointer;
    color: white;
    path {
      fill: white;
    }
  }
`;

export const imgFullScreen = ({
  isLoaded,
}: {
  isLoaded: boolean;
}): FlattenSimpleInterpolation => css`
  max-width: calc(100vw - 40px);
  transition: opacity 0.3s ease;
  opacity: ${isLoaded ? 1 : 0};
  object-fit: contain;
  height: 100%;
  display: block;
  margin: 0 auto;
`;

export const svg: any = css``;

export const img: any = css`
  :not(:first-child) {
    margin-top: 20px;
    span {
      margin-top: 20px;
    }
  }
  height: 100%;
  cursor: pointer;
  span {
    display: inline-block;
  }
`;

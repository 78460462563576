import { IGenAsset } from "src/common/__generated/root/types";

export const downloadFromAsset = async (downloadAsset: IGenAsset): Promise<void> => 
{
  if(!downloadAsset || !downloadAsset.src) 
  {
    console.error("Asset does not have a valid 'src'");
    return;
  }

  const downloadLink = downloadAsset.src;
  const fileName = downloadAsset.originalName;

  try 
  {
    const response = await fetch(downloadLink, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    });

    if(!response.ok) 
    {
      console.error(`Failed to fetch PDF. Status code: ${response.status}`);
      return;
    }

    const blob = await response.blob();
    // Create a URL for the blob data
    const blobUrl = window.URL.createObjectURL(blob);
    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.target = "_blank";
    anchor.download = fileName;
    anchor.click();

    // Clean up the URL object to release resources
    window.URL.revokeObjectURL(blobUrl);
  }
  catch(error) 
  {
    console.error("Error downloading PDF:", error);
  }
};

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
    width: 100%;
    .richText {
        br {
            height: 16px;
        }

        a {
            ${CSSWithOpacityHoverAnimation}
        }

        > *:not(:last-child) {
            margin-bottom: 16px;
        }

        ul,
        ol {
            transform: translateX(20px);

            > *:not(:last-child) {
                margin-bottom: 5px;
            }
        }

        ul {
            li {
                list-style-type: disc;
            }
        }

        ol {
            li {
                list-style-type: decimal;
            }
        }

        .iframe-outer {
            width: 100%;
            /* the iframe should fit full size into the screen which will not work if the screen has a wider aspect ratio then the 16:9 sometimes */
            @media (min-width: 1024px) {
                margin: 0 auto;
                /* 82px nav size and 72px padding left and right + 40px buffer */
                max-width: calc(((100vh - 82px) * 1.7777777777777778) - 72px - 72px - 40px);
            }
        }
        .iframe-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    .richText {
        br {
            height: 38px;
        }

        > *:not(:last-child) {
            margin-bottom: 38px;
        }
    }
`;

const Diamond = css``;

export const STextElementTextWrapper = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

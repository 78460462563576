export const getPageType = (props: any) => {
    switch (`${props?.slug}`) {
        case `${props?.Page?.slug}`:
            return "Page";
        case `${props?.PageBrandOverview?.slug}`:
            return "PageBrandOverview";
        case `${props?.PageNews?.slug}`:
            return "PageNews";
        case `${props?.PageGuestbook?.slug}`:
            return "PageGuestbook";
        case `${props?.PageDateInquiry?.slug}`:
            return "PageDateInquiry";
        case `${props?.PageRepairInquiry?.slug}`:
            return "PageRepairInquiry";
        case `${props?.PageImprint?.slug}`:
            return "PageImprint";
        case `${props?.PageBrand?.slug}`:
            return "PageBrand";
        case `${props?.PageContact?.slug}`:
            return "PageContact";
        case `${props?.PageMicro?.slug}`:
            return "PageMicro";
        case `${props?.PageCourse?.slug}`:
            return "PageCourse";
        default:
            return "PageUnknown";
    }
};

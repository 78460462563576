import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css<{areColsLessThanThree: boolean}>`

${({ areColsLessThanThree }) => !areColsLessThanThree && css`
> div > div {
  grid-template-columns: repeat(3, 1fr);
}
` 
}

padding-bottom: 48px;
`;

const Silver = css`

> div > div {
  grid-template-columns: repeat(12, 1fr);
}
`;

const Gold = css`
`;

const Platinum = css`
padding-bottom: 76px;
`;

const Diamond = css``;

export const STripleImageGrid = styled.div<{areColsLessThanThree: boolean}>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

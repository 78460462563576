import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  min-height: 312px;
  padding: 10px 12px;
`;

const Silver = css`
  padding: 22px;
  min-height: 348px;
`;

const Gold = css``;

const Platinum = css`
  padding: 38px;
  min-height: 528px;
`;

const Diamond = css``;

export const SFormTextarea = styled.textarea`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

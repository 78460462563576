import { Context } from "src/common";
import { IGenPageBrandMain } from "src/common/__generated/root/types";

import React, { FunctionComponent, useContext } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { BrandLogo } from "./BrandLogo";
import { SBrandsGallery } from "./styles/SBrandsGallery";

export const BrandsGallery: FunctionComponent = () => 
{
  const c = useContext(Context);
  if(!c) { return null; }
  
  const allRootBrandPages: IGenPageBrandMain[] = c.allRootBrandPages || [];
  return ( 
    <BrandAreaContainer>
      <SBrandsGallery>
        {
          allRootBrandPages && allRootBrandPages.length > 0 && 
          allRootBrandPages.map((brandPage, brandIndex) => (
            <BrandLogo key={brandPage.id || brandIndex} {...brandPage} />
          ))
        }
      </SBrandsGallery>
    </BrandAreaContainer>
  );
};

import {  IGenPageBrandMain } from "src/common/__generated/root/types";
import { Img } from "src/common/components/Img";

import React, { FunctionComponent } from "react";

import { SBrandDesciption } from "./styles/SBrandDesciption";
import { SBrandLogo } from "./styles/SBrandLogo";
import { SBrandName } from "./styles/SBrandName";
import Link from "next/link";

export const BrandLogo: FunctionComponent<IGenPageBrandMain> = ({
  brandDescription,
  nameInNavigation,
  brandLogo,
  slug,
}) =>
  brandLogo ? (
    <Link href={`/${slug}`} passHref>
      <SBrandLogo>
        {brandLogo.src && (
          <div className="brandImg">
            <Img src={brandLogo.src} alt={brandLogo.title} />{" "}
          </div>
        )}
        {nameInNavigation && <SBrandName>{nameInNavigation}</SBrandName>}
        {brandDescription && (
          <SBrandDesciption className="text-style-overwrite">
            {brandDescription}
          </SBrandDesciption>
        )}
      </SBrandLogo>
    </Link>
  ) : null;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    margin-top: 12px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css`
    gap: 38px;
    margin-top: 38px;
`;

const Diamond = css``;

export const SBrandTopicTeaserElementChunkWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

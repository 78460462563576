import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../../components/brand-area-container/BrandAreaContainer";
import { SSectionSeparator } from "./styles/SSectionSeparator";

const SectionSeparator: FunctionComponent = () => (
  <BrandAreaContainer>
    <SSectionSeparator />
  </BrandAreaContainer>
);

export default SectionSeparator;

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { css } from "styled-components";
import Spacer from "../../../BaseComponents/Spacer/Spacer";
import React from "react";

export interface IMicroPageFrame {
    __typename: "microPageFrame";
    id: string;
    src: string;
}

export const MicroPageFrame = ({ src }: IMicroPageFrame) => {
    return (
        <BrandAreaContainer>
            <iframe
                src={src}
                width="100%"
                css={css`
                    min-height: 100vh;
                `}
            />
            <Spacer bronze={40} />
        </BrandAreaContainer>
    );
};

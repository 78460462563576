// @ts-nocheck
import { css } from "styled-components";

export const BREAKPOINTS = {
    CHALLENGER: 3840,
    MASTER: 2840,
    DIAMOND: 1920, // 1080
    PLATINUM: 1366, // 900
    GOLD: 1024, // 768
    SILVER: 768, // 1024
    BRONZE: 375 // 667
};

export const BREAKPOINTS_DM = {
    bronze_375: `min-width: ${BREAKPOINTS.BRONZE / 16}em`,
    silver_768: `min-width: ${BREAKPOINTS.SILVER / 16}em`,
    gold_1024: `min-width: ${BREAKPOINTS.GOLD / 16}em`,
    platinum_1366: `min-width: ${BREAKPOINTS.PLATINUM / 16}em`,
    diamond_1920: `min-width: ${BREAKPOINTS.DIAMOND / 16}em`,
};

export const MIN_SILVER = (...args) => css`
  @media (min-width: ${BREAKPOINTS.SILVER / 16}em) {
    ${css(...args)};
  }
`;

export const MIN_GOLD = (...args) => css`
  @media (min-width: ${BREAKPOINTS.GOLD / 16}em) {
    ${css(...args)};
  }
`;
export const MIN_PLATINUM = (...args) => css`
  @media (min-width: ${BREAKPOINTS.PLATINUM / 16}em) {
    ${css(...args)};
  }
`;
export const MIN_DIAMOND = (...args) => css`
  @media (min-width: ${BREAKPOINTS.DIAMOND / 16}em) {
    ${css(...args)};
  }
`;
export const UNDER_PLATINUM = (...args) => css`
  @media (max-width: ${(BREAKPOINTS.PLATINUM - 1) / 16}em) {
    ${css(...args)};
  }
`;
export const UNDER_GOLD = (...args) => css`
  @media (max-width: ${(BREAKPOINTS.GOLD - 1) / 16}em) {
    ${css(...args)};
  }
`;
/* IE10+ CSS */
export const IS_IE = (...args) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${css(...args)};
  }
`;

/*
Match array of values to breakpoints
  */
export const MediaQueryArrayToVal = (mqArray, breakpoint) =>
{
    if(Array.isArray(mqArray))
    {
        return mqArray[
            Object.values(BREAKPOINTS)
                .reverse()
                .indexOf(breakpoint)
        ];
    }
    return mqArray;
};
const MEDIA = {
    MIN_SILVER,
    MIN_GOLD,
    MIN_PLATINUM,
    MIN_DIAMOND,
    UNDER_PLATINUM,
    UNDER_GOLD
};

export default MEDIA;

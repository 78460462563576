import React, { FunctionComponent, useState } from "react";

import * as styles from "./NavItemDesktop.style";

interface IProps
{
    link: string;
    active: boolean;
    label: string;
    dropdownItems: any;
}

const NavItemDesktop: FunctionComponent<IProps> = props =>
{
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div css={styles.wrapper} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <a
                href={props.link}
                css={`${styles.link} ${props.active || isHovered ? styles.active : null} `}
            >
                {props.label}
            </a>
            <div css={isHovered ? styles.dropDownWrapper : styles.inActive}>
                <a css={styles.spacer} href={props.link}/>
                <div css={styles.dropDown}>
                    {props.dropdownItems}
                </div>
            </div>

        </div>

    );
};

export default NavItemDesktop;

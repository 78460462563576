import React, { useState, Fragment, FunctionComponent, useEffect, useContext } from "react";

import { BurgerMenu } from "./BurgerMenu/BurgerMenu";
import * as styles from "./MainNavigation.style";
import NavItemDesktop from "./navItemDesktop/NavItemDesktop";
import { Context } from "src/common";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";


const MainNavigation: FunctionComponent = () =>
{
    const props = useContext(Context);


    const [isMenuActive, setIsMenuActive] = useState(false);
    const navigationItemsDesktop = [];
    const [location, setLocation] = useState("");

    const toggleMenu = (): void =>
    {
        setIsMenuActive(!isMenuActive);
    };

    useEffect(() =>
    {
        setLocation(window.location.pathname);
    }, []);

    const navigationItemsResponsive = props?.PrimaryNavigation?.items?.map((item, index) =>
    {
        if(!item?.link?.[0]?.slug || !item.label)
        {
            return;
        }

        const subNavItems = item?.subitems?.map((subItem, subIndex) =>
        {
            if(!subItem?.slug || !subItem.nameInNavigation)
            {
                return;
            }
            return (<li key={subIndex}><a css={styles.subNavItemResponsiveT4} href={subItem?.slug}>{subItem.nameInNavigation}</a></li>);
        });

        const setActive = item?.subitems?.find(subItem => location.includes(subItem?.slug));

        const navItemDesktop = (
            <NavItemDesktop key={index} link={item?.link?.[0]?.slug} active={location.includes(item?.link?.[0]?.slug) || !!setActive} label={item.label} dropdownItems={subNavItems}/>
        );

        navigationItemsDesktop.push(navItemDesktop);

        const toggleSubNavItemsMenu = (e): void =>
        {
            e.preventDefault();

            const el = document.getElementById(`navItem${String(index)}`);

            if(el.style.display === "none")
            {
                el.style.display = "block";
            }
            else
            {
                el.style.display = "none";
            }

        };

        const openSubMenuButton = <span css={styles.openSubMenuButtonT4} onClick={(e) => toggleSubNavItemsMenu(e)}>
            <svg css={styles.svgT4} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
        </span>;

        return (
            <Fragment key={index}>
                <li css={styles.navItemResponsiveT4}>
                    <a href={item?.link?.[0]?.slug} css={styles.linkResponsiveT4}>
                        {item.label}
                        {item.subitems ? openSubMenuButton: null}
                    </a>
                </li>
                <ul style={{ width: "99%", display: "none" }} id={`navItem${String(index)}`}>
                    {subNavItems}
                </ul>
            </Fragment>
        );
    });

    return (
        <div css={styles.navContainerT4}>
            <div css={styles.menuDesktopT4}>
                <a href={"/"}>
                    {props.CompanyInformationPublic?.logo?.[0]?.src ? <img css={styles.companyLogoT4} src={props.CompanyInformationPublic?.logo?.[0]?.src} alt={getContactCompanyNameShort()} /> : null}
                </a>
                <ul css={styles.ulMenuDesktopT4}>
                    {navigationItemsDesktop}
                </ul>
                <BurgerMenu isInSidebar={false} onClick={toggleMenu}/>
            </div>
            <div css={styles.menuResponsiveT4} style={{ transform: isMenuActive ? "translateX(0)" : "translateX(100%)" }}>
                <BurgerMenu isInSidebar={true} onClick={toggleMenu}/>
                <div css={styles.backDropT4} onClick={() => setIsMenuActive(false)}/>
                <ul css={styles.ulMenuResponsiveT4}>
                    {navigationItemsResponsive}
                </ul>
            </div>
        </div>
    );
};

export default MainNavigation;


import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { BackToOverviewLink } from "../back-to-overview-link/BackToOverviewLink";
import { BrandsDropdown } from "../brands-dropdown/BrandsDropdown";
import { SOverviewLinkWithDropdown } from "./styles/SOverviewLinkWithDropdown";

export const OverviewLinkWithDropdown: FunctionComponent = () => (
  <BrandAreaContainer>
  <SOverviewLinkWithDropdown>
    <BackToOverviewLink />
    <BrandsDropdown />
  </SOverviewLinkWithDropdown>
  </BrandAreaContainer>
);

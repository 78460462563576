import React, { FunctionComponent } from "react";

const ChevronDownIcon: FunctionComponent<{ size?: number }> = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 27 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.414063 2.51308L2.55807 0.414692L13.2533 11.3424L24.4366 0.39212L26.5355 2.53565L13.2082 15.5853L0.414063 2.51308Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDownIcon;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";
import { H1_768_Bold } from "../../../constantsOverwrites/typography";

const Default = css<any>`
    cursor: pointer;
    margin: 0 0 48px 0;
    svg {
        color: var(--primary-100);
        margin-right: 14px;
    }

    &,
    svg {
        ${CSSWithOpacityHoverAnimation}
    }

    display: flex;
    align-items: center;
    span {
        white-space: nowrap;
        color: var(--front-2, #505050);
        ${H1_768_Bold};
    }
    svg {
        width: 14px;
        height: 21px;
    }
`;

const Silver = css<any>`
    display: none;
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SBackToOverviewLinkMobile = styled.a`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

import { useContext } from "react";
import { Context } from "../..";

export const useBrandAreaMaxWidth = () => {
    const p = useContext(Context);
    if (p?.Settings?.template === "t5" || p?.Settings?.template === "t6") {
        return 1600;
    }
    if (p?.Settings?.template === "t1" || p?.Settings?.template === "t2") {
        return 955;
    }
    return 1366;
};

import React, { useState, FunctionComponent } from "react";
import { useDealerSearch } from "src/common/hooks/useDealerSearch";

import { SInputWrapper, Wrapper } from "./Search.styles";

const Search: FunctionComponent<{ isContact?: boolean }> = ({ isContact }) => {
  const [searchValue, setSearchValue] = useState("");
  const { triggerSearch } = useDealerSearch();

  return (
    <Wrapper isContact={isContact}>
      {!isContact && <h4>GUTE KÜCHEN IN IHRER NÄHE</h4>}
      <SInputWrapper
        isContact={isContact}
        onSubmit={(e) => {
          e.preventDefault();
          triggerSearch(searchValue);
        }}
      >
        <label>PLZ/Ort</label>
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <input type="submit" />
      </SInputWrapper>
    </Wrapper>
  );
};

export default Search;

import { BREAKPOINTS } from "src/common/constants/mediaquerys";
import { ISizes } from "../constants/types";

export const getImageMaxSizePerViewport = (params: ISizes): ISizes => 
{
  const viewportBreakpoints = [360, BREAKPOINTS.SILVER, BREAKPOINTS.PLATINUM, BREAKPOINTS.DIAMOND];
  
  const calculatedSizes = {};

  Object.entries(params).forEach(([breakpointName, size], index) => 
  {

    if(size && size.width && size.height) 
    {
      const givenWidth = size.width;
      const givenHeight = size.height;

      const ratio = typeof givenWidth === "number" ? givenWidth / viewportBreakpoints[index] : 1;

      const nextBreakpoint = viewportBreakpoints[index + 1];

      const maxWidth = nextBreakpoint ? Math.round((nextBreakpoint - 1) * ratio) : givenWidth;
      const maxHeight = nextBreakpoint ? Math.round((givenHeight * maxWidth) / givenWidth) : givenHeight;

      calculatedSizes[breakpointName] = { width: maxWidth, height: maxHeight };
    }
  });

  return calculatedSizes as ISizes;
};

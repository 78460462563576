export const COL_AMOUNT = {
  BRONZE: 4,
  SILVER: 12,
  GOLD: 12,
  PLATINUM: 12,
  DIAMOND: 12
};

export const GUTTER = {
  BRONZE: 24,
  SILVER: 24,
  GOLD: 38,
  PLATINUM: 38,
  DIAMOND: 38
};

export const PADDING = {
  BRONZE: 15,
  SILVER: 20,
  GOLD: 30,
  PLATINUM: 80,
  DIAMOND: 80
};

export const SCREEN_TYPES = {
  BRONZE: "BRONZE",
  SILVER: "SILVER",
  GOLD: "GOLD",
  PLATINUM: "PLATINUM",
  DIAMOND: "DIAMOND"
};


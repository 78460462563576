import { IGenDownloadElementComp } from "src/common/__generated/root/types";
import { RichText } from "src/common/components/RichText";
import { getImageMaxSizePerViewport } from "src/common/utils/getImageMaxSizePerViewport";

import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import FullScreenImageWithFocalPoint from "../full-screen-image-focal-point/FullScreenImageWithFocalPoint";
import { downloadFromAsset } from "./helperFunctions";
import { SDownloadElement } from "./style/SDownloadElement";
import { SDownloadElementImgWrapper } from "./style/SDownloadElementImgWrapper";

export const DownloadElement: FunctionComponent<IGenDownloadElementComp> = ({
  headline, subtitle, text, image, downloadAsset
}) =>  
{ 
  const sizes = getImageMaxSizePerViewport({
    bronze: { width: 264, height: 373 },
    silver: { width: 264, height: 373 },
    platinum: { width: 455, height: 642 }
  });

  return (
    <BrandAreaContainer>
      <SDownloadElement>
        <div className="layout">
          <div className="text">
            {headline && <h2 className="headline">{headline}</h2>}
            {subtitle && <h3 className="subtitle">{subtitle}</h3>}
            {text && <div className="richText"><RichText fragment textContent={text} /></div>}
          </div>
          {image && image.src && <SDownloadElementImgWrapper>
            <FullScreenImageWithFocalPoint
              img={image}
              sizes={sizes}
              objectFit="cover"
            />
          </SDownloadElementImgWrapper>}
        </div>
        {downloadAsset && (
          <button
            onClick={async () => 
            {
              await downloadFromAsset(downloadAsset);
            }}
            className="download-btn"
          >
            PDF laden
          </button>
        )}
      </SDownloadElement>
    </BrandAreaContainer>
  );
};

import styled, { css } from "styled-components";
import { BREAKPOINTS_DM } from "../../../../../common/constants/mediaquerys";

export const CSSWrapperLandingInput: any = css`
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 0 50px;
    width: calc(100% - 50px - 100px);
  }
`;

export const CSSWrapperLanding: any = css`
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 20px 0 36px 24px;
    border-left: 3px dotted;
    border-right: 3px dotted;
    margin-bottom: 60px;
  }
`;

export const Wrapper: any = styled.div<any>`
  margin-bottom: 20px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin-bottom: 0px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 12px;

  ${({ isContact }) => {
    return !isContact ? CSSWrapperLanding : "";
  }};

  h4 {
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 2px;
  }
`;

export const SInputWrapper = styled.form<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  border: 1px solid #85b2c3;
  padding: 5px 45px 40px 45px;

  width: calc(100% - 50px);
  ${({ isContact }) => {
    return !isContact ? CSSWrapperLandingInput : "";
  }};

  label {
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    font-weight: 600;
  }

  input[type="text"] {
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #85b2c3;
    border: none;
    &:focus {
      outline: 2px solid blue;
      border-radius: 4px;
    }
    @media (${BREAKPOINTS_DM.silver_768}) {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    }
  }

  input[type="submit"] {
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    position: absolute;
    bottom: -35%;
    left: 50%;
    padding: 0;
    margin: 0;
    margin-left: -33px;
    width: 78px;
    height: 76px;
    background: url(https://www.gk-gute-kuechen.de/images/icon/gk_icon_search.png)
      no-repeat;
    border: 0px;
    color: transparent;
  }
`;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 100%; /* 1 Aspect Ratio = 100% of width 100% */
    margin-bottom: 24px;
    display: block;
`;

const Silver = css`
    margin-bottom: 48px;
    margin-bottom: 0;
    width: 25%;
    padding-top: 25%; /* 1 Aspect Ratio = 100% of width 25% */
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SBrandHeaderLogoWrapper = styled.div`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

import Asset from "src/common/components/elements/Asset/Asset";

import React, { FunctionComponent } from "react";

import {
  IGenAsset,
  IGenBlockImageGridComp,
  IGenBrandTopicTeaserElementComp,
  IGenDoubleImageElementComp,
  IGenDownloadElementComp,
  IGenImageTextSideBySideElementComp,
  IGenSectionSeparatorComp,
  IGenSliderComp,
  IGenTextElement,
  IGenTextElementComp,
  IGenTripleImageElementComp,
  IGenTripleImageGridComp,
  IGenTwoColTextBlockComp,
} from "../../__generated/root/types";
import SeparatorGreyLine from "../BaseComponentsOverwrites/section-separator/SeparatorGreyLine";
import { BlockImageGrid } from "../components/block-image-grid/BlockImageGrid";
import { BrandPageHeader } from "../components/brand-page-header/BrandPageHeader";
import { BrandTopicTeaserElement } from "../components/brand-topic-teaser-element/BrandTopicTeaserElement";
import { DoubleImageElement } from "../components/double-image-element/DoubleImageElement";
import { DownloadElement } from "../components/download-element/DownloadElement";
import { ImageTextSideBySideElement } from "../components/image-text-side-by-side-element/ImageTextSideBySideElement";
import { OverviewLinkWithDropdown } from "../components/overview-link-with-dropdown/OverviewLinkWithDropdown";
import SliderWithFocalPoint from "../../BaseComponents/SliderWithFocalPoint/SliderWithFocalPoint";
import { SubtopicHeader } from "../components/subtopic-header/SubtopicHeader";
import { SubtopicHeaderLinks } from "../components/subtopic-header/SubtopicHeaderLinks";
import { TextElementLegacy } from "../components/text-element-legacy/TextElementLegacy";
import TextElement from "../components/text-element/TextElement";
import { TripleImageElement } from "../components/triple-image-element/TripleImageElement";
import TripleImageGrid from "../components/triple-image-grid/TripleImageGrid";
import TwoColTextBlock from "../components/two-col-text-block/TwoColTextBlock";
import { BackToOverviewLinkMobile } from "../components/back-to-overview-link-mobile/BackToOverviewLinkMobile";
import { IMicroPageFrame, MicroPageFrame } from "../components/micro-page-frame/MicroPageFrame";

interface IDropdown 
{
  __typename: "automaticallyIntegratedDropdown";
  id: string;
}
interface IGallery 
{
  __typename: "automaticallyIntegratedGallery";
  id: string;
}

interface IOverviewLinkWithDropdown 
{
  __typename: "automaticallyOverviewLinkWithDropdown";
  id: string;
}

interface IBrandHeader 
{
  __typename: "automaticallyIntegratedHeader";
  id: string;
}

interface ISubtopicHeader 
{
  __typename: "automaticallyIntegratedSubtopicHeader";
  id: string;
}
interface IBackToOverviewLinkMobile
{
  __typename: "automaticallyBackToOverviewLinkMobile";
  id: string;
}

interface ISubtopicHeaderLinks 
{
  __typename: "automaticallyIntegratedSubtopicHeaderLinks";
  id: string;
  width: string;
}


export type IBrandAreaComponentRouterElementComponent =
  | IGenAsset
  | IGenBrandTopicTeaserElementComp
  | IGenSliderComp
  | IGenTextElementComp
  | IGenTextElement
  | IGenBlockImageGridComp
  | IGenImageTextSideBySideElementComp
  | IGenDoubleImageElementComp
  | IGenTripleImageGridComp
  | IGenTripleImageElementComp
  | IGenSectionSeparatorComp
  | IGenTwoColTextBlockComp
  | IGenDownloadElementComp
  | IDropdown
  | IGallery
  | IBrandHeader
  | ISubtopicHeader
  | ISubtopicHeaderLinks
  | IBackToOverviewLinkMobile
  | IMicroPageFrame
  | IOverviewLinkWithDropdown;

export type IBrandAreaComponentRouterElement = IBrandAreaComponentRouterElementComponent & {noContainer?: boolean};

export const BrandAreaComponentRouter: FunctionComponent<{
  elements: IBrandAreaComponentRouterElement[];
}> = ({ elements }) => 
{
  if(!elements) 
  {
    return null;
  }
  return (
    <>
      {elements.map((element, i) => 
      {
        if(!element) 
        {
          return null;
        }
        const { __typename, id } = element;

        if(!__typename || !id) 
        {
          return null;
        }

        const reactKey = `${__typename}-${id}-${i}`;

        switch (__typename) 
        {
          case "BrandTopicTeaserElementComp":
            return (
              <BrandTopicTeaserElement
                key={reactKey}
                {...(element as IGenBrandTopicTeaserElementComp)}
              />
            );
          case "BlockImageGridComp":
            return (
              <BlockImageGrid
                key={reactKey}
                {...(element as IGenBlockImageGridComp)}
              />
            );
          case "TextElement":
            return (
              <TextElementLegacy
                key={reactKey}
                {...(element as IGenTextElement)}
              />
            );
          case "TextElementComp":
            return (
              <TextElement
                key={reactKey}
                {...(element as IGenTextElementComp)}
              />
            );
          case "TwoColTextBlockComp":
            return (
              <TwoColTextBlock
                key={reactKey}
                {...(element as IGenTwoColTextBlockComp)}
              />
            );
          case "DoubleImageElementComp":
            return (
              <DoubleImageElement
                key={reactKey}
                {...(element as IGenDoubleImageElementComp)}
              />
            );
          case "ImageTextSideBySideElementComp":
            return (
              <ImageTextSideBySideElement
                key={reactKey}
                {...(element as IGenImageTextSideBySideElementComp)}
              />
            );
          case "TripleImageGridComp":
            return (
              <TripleImageGrid
                key={reactKey}
                {...(element as IGenTripleImageGridComp)}
              />
            );
          case "SectionSeparatorComp":
            return <SeparatorGreyLine key={reactKey} />;
          case "TripleImageElementComp":
            return (
              <TripleImageElement
                key={reactKey}
                {...(element as IGenTripleImageElementComp)}
              />
            );
          case "Asset":
            return <Asset key={reactKey} {...(element as IGenAsset)} />;
          case "DownloadElementComp":
            return (
              <DownloadElement
                key={reactKey}
                {...(element as IGenDownloadElementComp)}
              />
            );
          case "automaticallyOverviewLinkWithDropdown":
            return <OverviewLinkWithDropdown key={reactKey} />;
          case "automaticallyBackToOverviewLinkMobile":
            return <BackToOverviewLinkMobile key={reactKey} />;
          case "automaticallyIntegratedHeader":
            return <BrandPageHeader key={reactKey} />;
          case "automaticallyIntegratedSubtopicHeader":
            return <SubtopicHeader key={reactKey} />;
          case "microPageFrame":
            return <MicroPageFrame key={reactKey} {...(element as IMicroPageFrame)}  />;
          case "SliderComp":
            return (
              <SliderWithFocalPoint
                key={reactKey}
                {...(element as IGenSliderComp)}
              />
            );
          case "automaticallyIntegratedSubtopicHeaderLinks":
            return <SubtopicHeaderLinks key={reactKey} />;
          default:
            return null;
        }
      })}
    </>
  );
};

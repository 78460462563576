import { Context } from "src/common";

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import { IGenBrandTopicTeaserElementComp, IGenBrandTopicTeaserItemComp } from "../../../__generated/root/types";
import ChevronRightIcon from "../../icons/ChevronRightIcon";
import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SBrandTopicTeaserElement, CSSItem } from "./styles/SBrandTopicTeaserElement";
import { SBrandTopicTeaserElementLink } from "./styles/SBrandTopicTeaserElementLink";
import { FancyImage } from "../../../BaseComponents/FancyImage/FancyImage";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";
import { SBrandTopicTeaserElementSecondaryImageWrapper } from "./styles/SBrandTopicTeaserElementSecondaryImageWrapper";
import { SBrandTopicTeaserElementChunkWrapper } from "./styles/SBrandTopicTeaserElementChunkWrapper";

export const BrandTopicTeaserElement: FunctionComponent<IGenBrandTopicTeaserElementComp> = ({ items }) => {
    const c = useContext(Context);
    const brandAreaMaxWidth = useBrandAreaMaxWidth();

    if (!items) {
        return null;
    }

    const secondaryImages = items.slice(1);
    const secondaryImagesChunkedIntoTwos: Array<IGenBrandTopicTeaserItemComp[]> = [];

    for (let i = 0; i < secondaryImages.length; i += 2) {
        secondaryImagesChunkedIntoTwos.push(secondaryImages.slice(i, i + 2));
    }

    const brandPageSlug = c?.PageBrand?.slug;

    const imageRatios = {
        half: {
            responsiveAspectRatio: {
                bronze: 312 / 312,
                silver: 348 / 232,
                platinum: 588 / 392,
            },
            responsiveFactor: { silver: 0.55 },
            responsiveMaxWidth: {
                platinum: brandAreaMaxWidth * 0.55,
            },
        },
        full: {
            responsiveAspectRatio: {
                bronze: 312 / 540,
                silver: 720 / 360,
                platinum: 1214 / 688,
            },
            responsiveFactor: undefined,
            responsiveMaxWidth: { gold: Math.max(1366, brandAreaMaxWidth * 1.1) },
        },
    };

    return (
        <BrandAreaContainer>
            <SBrandTopicTeaserElement>
                {items[0] && (
                    <>
                        <Link href={`/${brandPageSlug}/${items[0]?.link?.slug}`} passHref>
                            <a style={{ cursor: "pointer" }}>
                                <FancyImage
                                    img={items[0]?.teaserImage}
                                    responsiveAspectRatio={imageRatios.full.responsiveAspectRatio}
                                    responsiveMaxWidth={imageRatios.full.responsiveMaxWidth}
                                />
                                <SBrandTopicTeaserElementLink>
                                    <ChevronRightIcon />
                                    {items[0]?.label}
                                </SBrandTopicTeaserElementLink>
                            </a>
                        </Link>
                    </>
                )}
                <SBrandTopicTeaserElementSecondaryImageWrapper>
                    {secondaryImagesChunkedIntoTwos.map(
                        (chunk, chunkIndex) =>
                            chunk.length > 0 && (
                                <SBrandTopicTeaserElementChunkWrapper key={chunkIndex}>
                                    {chunk.map((item, itemIndex) => {
                                        const ratios = chunk.length == 2 ? imageRatios.half : imageRatios.full;
                                        return (
                                            item && (
                                                <Link
                                                    href={`/${brandPageSlug}/${item.link?.slug}`}
                                                    key={item.id || itemIndex}
                                                    passHref
                                                >
                                                    <a css={CSSItem} style={{ cursor: "pointer" }}>
                                                        <FancyImage
                                                            img={item.teaserImage}
                                                            responsiveAspectRatio={ratios.responsiveAspectRatio}
                                                            responsiveFactor={ratios.responsiveFactor}
                                                            responsiveMaxWidth={ratios.responsiveMaxWidth}
                                                        />
                                                        <SBrandTopicTeaserElementLink>
                                                            <ChevronRightIcon />
                                                            {item?.label}
                                                        </SBrandTopicTeaserElementLink>
                                                    </a>
                                                </Link>
                                            )
                                        );
                                    })}
                                </SBrandTopicTeaserElementChunkWrapper>
                            )
                    )}
                </SBrandTopicTeaserElementSecondaryImageWrapper>
            </SBrandTopicTeaserElement>
        </BrandAreaContainer>
    );
};

import { Context } from "src/common";
import { IGenPageBrandMain } from "src/common/__generated/root/types";

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import ChevronRightIcon from "../../icons/ChevronRightIcon";
import { SBrandPageHeaderLink } from "../brand-page-header/styles/SBrandPageHeaderLink";
import { SSubtopicLinksWrapper } from "./styles/SSubtopicLinksWrapper";

export const SubtopicHeaderLinks: FunctionComponent = () => {
    const c = useContext(Context);
    const page = c?.PageBrand as unknown as IGenPageBrandMain;
    const brandSubtopicPages = page?.subtopics || [];

    return (
        <SSubtopicLinksWrapper>
            {brandSubtopicPages &&
                brandSubtopicPages.map((subtopic, index) => (
                    <Link href={`/${page.slug}/${subtopic.slug}`} key={index} passHref>
                        <SBrandPageHeaderLink key={index}>
                            <ChevronRightIcon />
                            &nbsp;<span>{subtopic?.nameInNavigation}</span>
                        </SBrandPageHeaderLink>
                    </Link>
                ))}
        </SSubtopicLinksWrapper>
    );
};

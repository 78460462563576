import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  width: calc(50% - 12px);
  cursor: pointer;
  margin-bottom: 24px;
  aspect-ratio: 1/1;
  .brandImg {
    width: 100%;
    position: relative;
    aspect-ratio: 1/1;
    border: 1px solid var(--back-3, #f9f9f9);
    background: #fefefe;
    box-sizing: content-box;
    > div {
    padding: 18px;
      :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding-top: 100%;
    }
    img {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    }
   
  }
`;

const Silver = css`
  width: calc(25% - (24px * 3 / 4)); 
  .brandImg {
    div> {
      padding: 24px;
    }
  }
`;

const Gold = css``;

const Platinum = css`
  width: calc(25% - (38px * 3 / 4)); 
  margin-bottom: 38px;
  .brandImg {
    > div{
      padding: 38px;
    }
  }
`;

const Diamond = css``;

export const SBrandLogo = styled.a<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

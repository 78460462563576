import { IGenTripleImageGridComp } from "src/common/__generated/root/types";
import Gallery from "src/common/brand-area/BaseComponentsOverwrites/Gallery/Gallery";
import { calculateResponsiveImagesSizes } from "src/common/utils/calculateResponsiveImagesSizes";

import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { STripleImageGrid } from "./styles/STripleImageGrid";
import { FancyImageWithModal } from "../../../BaseComponents/FancyImageWithModal/FancyImageWithModal";

const TripleImageGrid: FunctionComponent<IGenTripleImageGridComp> = ({ images }) => {
    const areColsLessThanThree = images.length < 3;
    const colsCount = areColsLessThanThree ? images.length : 3;
    const sizes = calculateResponsiveImagesSizes({ responsiveFactor: { bronze: 1 / colsCount }, images });

    if (images.length < 0) {
        return null;
    }

    return (
        <BrandAreaContainer>
            <STripleImageGrid areColsLessThanThree={areColsLessThanThree}>
                <Gallery fullWidth bronze={colsCount}>
                    {images?.map((image) => {
                        if (!image || !image?.id) {
                            return null;
                        }
                        return (
                            <FancyImageWithModal
                                key={image.id}
                                img={image}
                                responsiveAspectRatio={{ 
                                  // use for all images the same aspect ratio
                                  bronze: sizes.bronze.width / sizes.bronze.height
                                }}
                                responsiveFactor={{ silver: 0.34 }}
                                responsiveMaxWidth={{ platinum: 1366 * 0.34 }}
                            />
                        );
                    })}
                </Gallery>
            </STripleImageGrid>
        </BrandAreaContainer>
    );
};

export default TripleImageGrid;

import React, { FunctionComponent, Fragment, useContext } from "react";
import { Context, ShareLinks } from "src/common";
import { LineLogo } from "src/common/components/LineLogo";
import { getContactCompanyNameShort } from "../../../../../common/components/ImprintStart";

import * as styles from "./Footer.style";
import { CopyrightNotice } from "../../../../../common/components/CopyrightNotice/CopyrightNotice";
import { css } from "styled-components";

const linksSeparator = <span style={{ margin: "0 3px" }}>|</span>;

export const Footer: FunctionComponent = () => {
  const props = useContext(Context);
  const companyInformation = props?.CompanyInformationPublic;
  const footerNavItems = props?.Footer?.Navigationspunkte || [];

  return (
    <div css={styles.wrapper}>
      <div css={styles.addressWrapper}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div css={styles.lineLogo}>
            <LineLogo />
          </div>
          <div>
            <p css={styles.companyName}>{getContactCompanyNameShort()}</p>
            <p>{companyInformation?.street}</p>
            <p>
              {companyInformation?.postcode} {companyInformation?.place}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          Telefon: {companyInformation?.phone} -
          <a href={`mailto:${companyInformation?.email}`}> E&#8209;Mail</a>
          {linksSeparator}
          {footerNavItems?.map((item, i) => {
            const slug = item?.slug;
            if (!slug || !item.nameInNavigation) {
              return null;
            }
            return (
              <Fragment key={i}>
                <a href={slug} style={{ textTransform: "uppercase" }}>
                  {item.nameInNavigation}
                </a>
                {i !== footerNavItems.length - 1 && linksSeparator}
              </Fragment>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "8px",
            fontSize: "12px",
          }}
          css={css`
              display: flex;
              justify-content: flex-end;
          `}
        >
          <CopyrightNotice />
        </div>
      </div>
      <div css={styles.shareOnSocialMediaWrapper}>
        <ShareLinks />
      </div>
    </div>
  );
};

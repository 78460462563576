import { Context, RichText, getChunkedRichtext, getPage } from "src/common";
import { IGenTextElement } from "src/common/__generated/root/types";

import React, { FunctionComponent, useContext, useState } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { STextElement } from "./styles/STextElement";
import { STextElementTextWrapper } from "./styles/STextElementTextWrapper";
import { STextElementCollapseButton } from "./styles/STextElementCollapseButton";
import { richToPlainText } from "../../../utils/text/plaintext";

export const TextElementLegacy: FunctionComponent<
    IGenTextElement & { width?: string; noContainer?: boolean; collapseAfterXDisabled?: boolean }
> = ({ text, headline, noContainer, collapseAfterXDisabled }) => {
    const props = useContext(Context);
    const page = getPage(props);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const { chunkedText, didChunk } = getChunkedRichtext({ text });
    let renderedRichtext = text;

    const isPageTextCollapsible = page?.__typename === "PageBrandOverview" && !collapseAfterXDisabled && didChunk;

    if (isPageTextCollapsible) {
        renderedRichtext = isCollapsed ? chunkedText : text;
    }

    if (!renderedRichtext && !headline) return null;
    return (
        <BrandAreaContainer noContainer={noContainer}>
            <STextElement isRichtextComponent={!!renderedRichtext}>
                <STextElementTextWrapper>
                    <div className="richText">
                        {headline && <h1 className="headline">{headline}</h1>}
                        {text && <RichText iframeContainer fragment textContent={renderedRichtext} />}
                    </div>
                    {isPageTextCollapsible && (
                        <STextElementCollapseButton onClick={() => setIsCollapsed((prevState) => !prevState)}>
                            <span className="mark">{isCollapsed ? "+" : "-"}</span>&nbsp;{" "}
                            <span className="text">{isCollapsed ? "weiterlesen" : "weniger anzeigen"}</span>
                        </STextElementCollapseButton>
                    )}
                </STextElementTextWrapper>
            </STextElement>
        </BrandAreaContainer>
    );
};

import React, { FC,  HTMLProps } from "react";
import styled, { StyledFunction }from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Container = styled.div`
  width: 100%;
`;

export const Grid = styled.div`
  display: flex;
  max-width: 950px;
  margin: 0 auto;
`;

export const Section = styled.div`
  flex: 1;
  background-color: #F8F8F8;
  box-shadow: 2px 2px 5px rgba(50,50,50,.5);
`;

interface IGridProps {
  sm?: number;
  md?: number;
  expand?: boolean;
}

export const Col = styled.div<IGridProps>`
  width: ${({sm}) => sm ? 100 / 12 * sm : 100}%;

  @media (${BREAKPOINTS_DM.silver_768}){
    ${({md}) => md && `width: ${100 / 12 * md}%;`}
  }
`;

export const Row: FC<any> = styled.div<IGridProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ expand }) => expand && `
    margin-left: -10px;
    margin-right: -10px;
  `}

  ${Col} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

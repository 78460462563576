import { css } from "styled-components";
import { useDealerSearch } from "../../../../../common/hooks/useDealerSearch";

export const DachseiteSearch = () => {
  const { triggerSearch } = useDealerSearch();

  return (
    <div>
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            text-align: center;
            margin: 0 auto;
            width: 100%;
            max-width: 256px;
          `}
        >
          <img src="images/dk_home_search_image.jpeg" />
          <h3
            css={css`
              line-height: 20px;
              letter-spacing: 3px;
              text-transform: uppercase;
              color: white;
              font-size: 14px;
              text-align: center;
              padding: 10px 15% 30px;
              text-align: center;
              margin: 0 auto;
            `}
          >
            FINDEN SIE DAS KÜCHENHAUS IN IHRER NÄHE
          </h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              triggerSearch((e.target as any)?.elements?.[0]?.value);
            }}
            css={css`
              display: flex;
              flex-direction: column;
              position: relative;
              width: 100%;
              max-width: 256px;
              top: 44%;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 256px;
              `}
            >
              <input
                type="text"
                placeholder="PLZ/ORT"
                name="search"
                css={css`
                  height: 40px;
                  font-size: 14px;
                  background-color: transparent;
                  border: 2px solid white;
                  outline: none;
                  padding-left: 5px;
                  color: white;
                  text-align: center;
                  text-overflow: ellipsis;
                  width: 100%;
                  overflow: hidden;
                  padding-right: 30px;
                `}
              />
            </div>
            <button
              name="search"
              type="submit"
              css={css`
                position: absolute;
                top: 0;
                right: 10px;
                width: 30px;
                height: 40px;
                margin: auto;
                opacity: 0.6;
                border: none;
                outline: none;
                letter-spacing: 0.2px;
                font-size: 12px;
                line-height: 40px;
                color: white;
              `}
            >
              &gt;&gt;
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface ISBrandsDropdownIconWrapperProps 
{
  isOpen: boolean;
  hasNameInNavigation: boolean;
}

const Default = css<ISBrandsDropdownIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  transition: color 0.3s ease-in;

  ${({ isOpen }) => isOpen ? css`
  color: var(--back-3);
  ` : css`
  color: var(--primary-100);
  `}

  ${({ hasNameInNavigation, isOpen }) => !hasNameInNavigation && isOpen && css`
  color: var(--back-3);
  `}

  svg {
    transition: transform 0.3s ease-in;
    ${({ isOpen }) => isOpen && css`transform: rotate(180deg);`}
  }

  svg {
    width: 21px;
    height: 12px;
  }
`;

const Silver = css`
  svg {
    width: 23px;
    height: 13px;
  }
`;

const Gold = css``;

const Platinum = css`
width: 76px;

  svg {
    width: 27px;
    height: 16px;
  }
`;

const Diamond = css``;

export const SBrandsDropdownIconWrapper = styled.span`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

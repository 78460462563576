import styled, { css, keyframes } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../../constants/mediaquerys";

interface SImageLoaderPlaceholderProps 
{
  bgPlaceHolder: string;
  isLoaded: boolean;
  width: number;
  height: number;
}

const pulseAnimation = keyframes`
  0% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
`;

const Default = css<SImageLoaderPlaceholderProps>`
display: ${({ isLoaded }) => (isLoaded ? "none" : "block")};
  border-radius: 8px;
  opacity: 0;
  width: ${({ width }) => width - 40}px;
  height: ${({ height }) => height}px;
  max-width: calc(100vw - 40px);
  max-height: 100%;
  background-color: ${({ bgPlaceHolder }) => bgPlaceHolder && bgPlaceHolder};
  animation: ${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SModalImageLoader = styled.div`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

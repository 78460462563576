import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
  align-self: flex-end;
  width: 100%;
  background-color: var(--primary-100);
  padding: 12px;
  color: var(--special-logobg);
  font-size: 17px;
  font-weight: 700;
  line-height: 150% !important;
  
  ${CSSWithOpacityHoverAnimation}
`;

const Silver = css`
  font-size: 18px;
  padding: 10.5px 22px;
`;

const Gold = css``;

const Platinum = css`
  font-size: 24px;
  padding: 21px 38px;
  margin-top: calc(61px - 19px);
`;

const Diamond = css``;

export const SFormButtonSubmit = styled.button`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

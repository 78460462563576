import {
    H1_1366_Bold,
    H1_360_Regular,
    H1_768_Bold,
    H2_1366_Regular,
    H2_768_Regular,
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css<any>`
    padding-bottom: 48px;
    .content {
        width: 100%;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--back-3);

        .back-to-brand {
            display: flex;
            align-items: center;
            color: var(--front-2);
            cursor: pointer;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;
                ${H1_768_Bold};
                color: var(--front-2);
            }
            svg {
                color: var(--primary-100);
                width: 14px;
                height: 21px;
            }

            span,
            svg {
                ${CSSWithOpacityHoverAnimation}
            }
            a {
                text-decoration: none;
            }
            margin-bottom: 24px;
        }

        .subtopic-name {
            p {
                color: var(--front-2);
                ${H1_360_Regular};
            }
        }
    }
`;

const Silver = css<any>`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 75%;
        .back-to-brand {
            margin-bottom: 0;
        }
        .subtopic-name {
            p {
                ${H2_768_Regular};
            }
        }
    }
`;

const Gold = css``;

const Platinum = css<any>`
    padding-bottom: 76px;
    gap: 38px;

    .content {
        padding-bottom: 38px;

        div.back-to-brand {
            span {
                ${H1_1366_Bold};
            }
            svg {
                width: 18px;
                height: 28px;
            }
        }

        .subtopic-name {
            p {
                ${H2_1366_Regular};
            }
        }
    }
`;

const Diamond = css``;

export const SSubtopicHeader = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;


import React, { FC, useContext } from "react";
import { Context, IContext } from "src/common";

import { Grid } from "../../../BaseComponents/Grid";
import { HaderContainer, Logo } from "./Header.styles";
import Navigation from "./Navigation";

const Header: FC = () => 
{
  const props = useContext<IContext>(Context);
  const logo = props.CompanyInformationPublic?.logo?.[0];

  return (
    <HaderContainer>
      <Grid>
        {logo && <a href="/"><Logo src={logo.src} /></a>}
        <Navigation items={props.PrimaryNavigation?.items} slug={props.slug} />
      </Grid>
    </HaderContainer>
  );
};

export default Header;

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
.right{
    display:none;
}
`;

const Silver = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  .left {
      width: 50%;
    }
    .right {
        display: block;
        width: 50%;
        margin-bottom: 20%;
        display: flex;
        flex-direction: column;
        // 20px of the img + 4px gap = desired 24px space
        gap:4px;
    }
    margin-bottom:48px;
    `;

const Gold = css``;

const Platinum = css`
    gap: 38px;
    .right{
      // 20px of the img + 18px of the gap = desired 38px space
      gap:18px;
    }
    margin-bottom:76px;
`;

const Diamond = css``;

export const STripleImageElement = styled.div<any>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

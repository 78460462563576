import React, { FunctionComponent } from "react";

const CloseIcon: FunctionComponent<{size?: number}> = ({ size= 20 }) => 
  (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 29 29" fill="none">
      <path d="M28 1L1 28" stroke="currentColor" strokeWidth="2"/>
      <path d="M1 1L28 28" stroke="currentColor" strokeWidth="2"/>
    </svg>
  );

export default CloseIcon;

import Link from "next/link";
import React, { FunctionComponent, useContext } from "react";

import { Context } from "../../..";
import { IGenPageBrandOverview } from "../../../types_dealer_gen";
import ChevronLeftIcon from "../../icons/ChevronLeftIcon";
import { SBackToOverviewLink } from "./styles/SBackToOverviewLink";

export const BackToOverviewLink: FunctionComponent = () => 
{
  const props = useContext(Context);
  const page = props?.PageBrandOverview as IGenPageBrandOverview;

  let href = "/marken";
  let name = "Marken";

  if(page?.nameInNavigation) 
  {
    name = page.nameInNavigation;
  }
  if(page?.slug) 
  {
    href = `/${page.slug}`;
  }

  return (
    <Link href={href} passHref>
      <SBackToOverviewLink>
        <ChevronLeftIcon />
        <span>{name}</span>
      </SBackToOverviewLink>
    </Link>
  );
};

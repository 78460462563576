import React, { FunctionComponent } from "react";
import SlickSlider from "react-slick";
import { ISlider } from "src/common";
import Img from "../../../BaseComponents/Img/Img";
import { centeredContent } from "../../../constants/layout";
import SSlickBase from "../../../utils/slick/SSlickBase";
import SSlickTheme from "../../../utils/slick/SSlickTheme";
import {SSliderComponent} from "./Styles/SSliderComponent";

const Slider: FunctionComponent<ISlider> = props => {
    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        autoplay: true,
    };

    return (
        <SSliderComponent>
            <div css={centeredContent}>
                <div css={`
                        ${SSlickBase}
                        ${SSlickTheme}
                    `}>
                    <SlickSlider {...sliderSettings}>
                        {props?.elements.map((element, i) => (
                            <div key={`SlickSlider${i}`} >
                                <Img quality={1600} src={element?.src} alt={element?.description ?? "image"} />
                            </div>
                        ))}
                    </SlickSlider>
                </div>
            </div>
        </SSliderComponent>
    );
};

export default Slider;

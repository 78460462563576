import {
  H1_1366_Bold,
  H1_768_Bold,
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css<any>`
    display: none;
    cursor: pointer;
    margin: 0 0 48px 0;
    svg {
        color: var(--primary-100);
        margin-right: 14px;
    }

    &,
    svg {
        ${CSSWithOpacityHoverAnimation}
    }
`;

const Silver = css<any>`
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
    color: var(--front-2, #505050);
    ${H1_768_Bold};
  }
  svg {
    width: 14px;
    height: 21px;
  }
`;

const Gold = css``;

const Platinum = css<{ isRichtextComponent: boolean }>`
  margin: 0 0 76px 0;
  span {
    ${H1_1366_Bold};
  }
  svg {
    width: 18px;
    height: 28px;
  }
`;

const Diamond = css``;

export const SBackToOverviewLink = styled.a`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

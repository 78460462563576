import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  position: relative;
  display: block;
  min-width: 300px;
  width: 100%;
  margin: 0px 0 48px 0;
`;

const Silver = css`
  margin-bottom: 0px;
  position: relative;
  z-index: 5;
  float: right;
  width: 50%;
`;

const Gold = css``;

const Platinum = css`
  margin: 0px 0 76px 0;
`;

const Diamond = css``;

export const SBrandsDropdown = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

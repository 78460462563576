import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
text-align: center;
color: #DC3925;
margin-bottom: 10px;

&.text-overwrite {
  font-weight: 700;
}
`;

const Silver = css`
`;

const Gold = css``;

const Platinum = css`
`;

const Diamond = css``;

export const SFormErrorMessage = styled.h4`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

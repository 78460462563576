// @ts-nocheck
import { FC } from "react";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import styled, { css } from "styled-components";
import { Container } from '../../../BaseComponents/Grid';
import { Grid } from '../../../BaseComponents/Grid';

export const BurguerIcon: any = styled.div`
  height: 34px;
  width: 57px;
  background-image: url("/images/sprite-sa3ca3ca63e.png");
  background-position: 0px -305px;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(64px / 2 - 34px / 2);
  left: 10px;
  cursor: pointer;
  z-index: 109;
`;


export const HaderContainer = styled(Container)`
  background-color: #000;
  height: 64px;
  position: fixed;
  z-index: 109;

  ${Grid} {
    height: 100%;
    position: relative;
    z-index: 109;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    position: relative;
  }
`;

export const Logo = styled.img`
  margin: 10px;
  position: absolute;
  display: block;

  width: calc(100% - 97px);
  max-width: 250px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: unset;
  }
`;

export const NavigationLink: FC<any> = styled.li<{ active: boolean }>`
  display: flex;

  :last-child {
    border-right: none;
  }

  > a {
    background-image: linear-gradient(to left,#d0d2d3 0,#d0d2d3 95%);
    color: ${({ active, theme }) => active ? theme.palette.green : theme.palette.black};
    display: block;
    font-family: sofia_pro_extralightregular, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 2px;
    padding-left: 10.5px;
    width: 100%;

    @media (${BREAKPOINTS_DM.silver_768}) {
      color: ${({ active, theme }) => active ? theme.palette.green : "#F8F8F8"};
      border-right: 1px solid #3d3d3d;
      line-height: 20px;
      padding-left: 9.5px;
      padding-right: 10.5px;
      // padding-top: 4px;
      // padding-bottom: 4px;
      margin-bottom: 0px;
      background-image: none;
      wdith: unset;
    }
  }
`;

export const Icon: any = styled.img`

`;

export const NavigationLinks = styled.div`
  position: absolute;

  background-color: #000;
  display: block;
  align-items: center;
  padding: 64px 0 0 0;

  transform: translate3d(70vw,0,0);
  transition: transform .8s ease;
  width: 70vw;
  height: 100vh;
  box-shadow: -2px -2px 5px rgba(50,50,50,.5);

  ${BurguerIcon} {
    display: none;
  }

  ul {
    list-style: none;
    overflow-y: auto;
    height: calc(100vh - 64px);
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    transform: translate3d(0,0,0);;
    right: 0;
    width: unset;
    height: unset;
    max-width: calc(100% - 270px);
    padding: .5em 0;
    box-shadow: none;
    ${NavigationLink} {
      display: flex;
    }

    ${BurguerIcon} {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: unset;
    }
  }
`;

export const NavigationContainer: FC<any> = styled.div<{ active: boolean }>`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  background-color: #000;

  ${({ active }) => active
    ? `
      width: 70vw;
      ${NavigationLinks} {
        ${BurguerIcon} {
          display: block;
        }
        transform: translate3d(0,0,0);
      }

      ${BurguerIcon} {
        display: none;
      }
    `
    : `
      width: 77px;
    `
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 100%;
    ${BurguerIcon} {
      display: none;
    }
  }
`;
import { FriendlyCaptcha } from "src/common/components/friendly-capture";

import React, { FormEventHandler, FunctionComponent, useState, useContext } from "react";

import CloseIcon from "../../icons/CloseIcon";
import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SForm } from "./styles/SForm";
import { SFormButtonSubmit } from "./styles/SFormButtonSubmit";
import { SFormErrorMessage } from "./styles/SFormErrorMessage";
import { SFormInput } from "./styles/SFormInput";
import { SFormLabel } from "./styles/SFormLabel";
import { SFormSuccessMessage } from "./styles/SFormSuccessMessage";
import { SFormTextarea } from "./styles/SFormTextarea";
import { handleFormSubmit } from "../../../utils/formSubmit";
import { Context } from "../../..";
import { css } from "styled-components";

const Form: FunctionComponent = () => {
    const props = useContext(Context);
    const brandOverviewFormIntro =
        props?.UniversalTexts?.brandOverviewFormIntro ??
        `Haben Sie Fragen zu unseren Marken und Herstellern? Nutzen Sie einfach das Kontaktformular hier und schreiben Sie uns.`;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [hasPrivacyChecked, setHasPrivacyChecked] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    // const lineShort = useLineShort();

    const submitForm: FormEventHandler<HTMLFormElement> = async (e: any) => {
        e.preventDefault();

        if (!hasPrivacyChecked) {
            alert("Bitte akzeptieren Sie die Datenschutzerklärung");
            return;
        }

        const data = {
            Name: name,
            Email: email,
            "E-Mail": email,
            Message: message,
            Nachricht: message,
        };

        try {
            await handleFormSubmit({
                ...data,
                checkbox: hasPrivacyChecked,
                line: props?.CompanyInformationPrivate?.line,
                subject: `Neue Anfrage über Ihre Homepage auf der Markenseite ${
                    data?.Name ? `von ${data?.Name} ` : ""
                }`,
                dealerMail: props?.CompanyInformationPublic?.email
                    ? `${props?.CompanyInformationPublic?.email}`
                    : undefined,
                templateId: "d-26922068a30343d98a725c3fdaa663da",
            });

            setIsSuccess(true);
        } catch (e) {
            setHasError(true);
            setIsSuccess(false);
            return;
        }
    };

    return (
        <BrandAreaContainer>
            <div className="richText">
                <p>{brandOverviewFormIntro}</p>
                <div
                    css={css`
                        width: 100%;
                        height: 1rem;
                    `}
                ></div>
            </div>
            <SForm>
                {isSuccess && (
                    <SFormSuccessMessage className="text-overwrite">
                        Ihre Nachricht wurde erfolgreich versendet, vielen Dank.
                    </SFormSuccessMessage>
                )}

                {hasError && (
                    <SFormErrorMessage className="text-overwrite">
                        Es ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Eingaben.
                    </SFormErrorMessage>
                )}

                {!isSuccess && (
                    <form onSubmit={submitForm}>
                        <div className="left-side">
                            <SFormTextarea
                                required
                                name="nachricht"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                placeholder="Nachricht"
                                rows={5}
                            />
                        </div>
                        <div className="right-side">
                            <SFormInput
                                type="text"
                                required
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                placeholder="Name"
                            />
                            <SFormInput
                                type="email"
                                required
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                placeholder="E-Mail"
                            />
                            <FriendlyCaptcha />
                            <SFormLabel htmlFor="privacy" isChecked={hasPrivacyChecked}>
                                <span className="checkbox">
                                    <CloseIcon />
                                    <SFormInput
                                        id="privacy"
                                        type="checkbox"
                                        required
                                        name="privacy"
                                        onChange={(e) => setHasPrivacyChecked(e.target.checked)}
                                        checked={hasPrivacyChecked}
                                        placeholder="Name"
                                    />
                                </span>
                                <span className="checkbox-content">
                                    Ich akzeptiere die&nbsp;
                                    <a href="/imprint-datenschutz#Datenschutz">Datenschutzerklärung</a>
                                </span>
                            </SFormLabel>
                            <SFormButtonSubmit type="submit" value="Absenden">
                                Absenden
                            </SFormButtonSubmit>
                        </div>
                    </form>
                )}
            </SForm>
        </BrandAreaContainer>
    );
};

export default Form;

import {
  P_1366_Regular,
  P_360_Regular,
  P_768_Regular,
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
  display: flex;
  gap: 8px;
  align-items: baseline;

    svg {
        color: var(--primary-100);
        width: 12px;
        height: 14px;
    }
    cursor: pointer;
    color: var(--front-2);
    ${P_360_Regular}
    margin-top: 12px;
    margin-bottom: 12px;

    &,
    svg {
        ${CSSWithOpacityHoverAnimation}
    }
`;

const Silver = css`
  ${P_768_Regular}
  margin-bottom: 0px;

`;

const Gold = css``;

const Platinum = css`
  margin-top: 20px;
  ${P_1366_Regular}
  svg {
    width: 13px;
    height: 18px;
  }
`;

const Diamond = css``;

export const SBrandTopicTeaserElementLink = styled.p`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/mediaquerys";

interface Interface
{
    inSidebar: boolean;
}

const Default = css`
  height: 36px;
  width: 57px;
  background-image: url("/images/sprite-s82c5aa31b4.png");
  background-position: -12px -74px;
  background-repeat: no-repeat;
  position: ${(props: Interface) => props.inSidebar ? "absolute" : "fixed"};
  top: 20px;
  right: 0;
  left: ${(props: Interface) => props.inSidebar ? "31%" : "initial"};
  cursor: pointer;
  z-index: 999;
`;

const Silver = css``;

const Gold = css`
  display: none;
`;

const Platinum = css``;

const Diamond = css`
`;

const SBurgerMenu = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SBurgerMenu;

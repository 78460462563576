import styled, { css, keyframes } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface SImageLoaderPlaceholderProps 
{
  bgPlaceHolder: string;
  isLoaded: boolean;
}

const pulseAnimation = keyframes`
  0% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
`;

const Default = css<SImageLoaderPlaceholderProps>`
display: ${({ isLoaded }) => (isLoaded ? "none" : "block")};
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: ${({ bgPlaceHolder }) => bgPlaceHolder && bgPlaceHolder};
  animation: ${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  position: absolute;
  top: 0;
  left: 0;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SImageLoadingPlaceholder = styled.div<SImageLoaderPlaceholderProps>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

import { P_1366_Regular, P_360_Regular, P_768_Regular } from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css`
    display: flex;
    cursor: pointer;
    align-items: baseline;
    gap: 2px;
    color: var(--front-2);
    
    span {
        ${P_360_Regular};
    }

    svg {
        color: var(--primary-100);
        width: 9px;
        min-width: 9px;
        height: 12px;
    }

    &,
    svg {
        ${CSSWithOpacityHoverAnimation}
    }

    &:not(:last-child) {
        padding-bottom: 8px;
    }
`;

const Silver = css`
    span {
        ${P_768_Regular};
    }
`;

const Gold = css``;

const Platinum = css`
    span {
        ${P_1366_Regular};
    }
    svg {
        width: 12px;
        min-width: 12px;
        height: 18px;
    }
`;

const Diamond = css``;

export const SBrandPageHeaderLink = styled.a<any>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

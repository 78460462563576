
import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/mediaquerys";
import { ImageHeightPerBreakpoint } from "../ImageWithFocalPoint";

interface SImageWithFocalPointProps
{
  loaded: boolean;
  objectFit: "contain" | "cover";
  heightPerBreakpoint: ImageHeightPerBreakpoint;
}

const Default = css<SImageWithFocalPointProps>`
  transition: opacity 0.8s ease;
  width: 100%;
  height: 100%;
  position: relative;
  img {
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
    object-fit: ${({ objectFit }) => objectFit || ""};
    width: 100%;
    height: 100%;
    display: block;
  }

  ${({ loaded, heightPerBreakpoint }) => !loaded && css`
    min-height: ${heightPerBreakpoint.bronze}px;
  `};
`;

const Silver = css<SImageWithFocalPointProps>`
  ${({ loaded, heightPerBreakpoint }) => !loaded && css`
    min-height: ${heightPerBreakpoint.silver}px;
  `};
`;

const Gold = css<SImageWithFocalPointProps>`
  ${({ loaded, heightPerBreakpoint }) => !loaded && css`
    min-height: ${heightPerBreakpoint.gold}px;
  `};
`;

const Platinum = css<SImageWithFocalPointProps>`
  ${({ loaded, heightPerBreakpoint }) => !loaded && css`
    min-height: ${heightPerBreakpoint.platinum}px;
  `};
`;

const Diamond = css``;

const SImageWithFocalPoint = styled.div<SImageWithFocalPointProps>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SImageWithFocalPoint;

import {
    H2_1366_Bold,
    H2_360_Bold,
    H2_768_Bold,
    P_Large_1366_Bold,
    P_Large_360_Bold,
    P_Large_768_Bold,
} from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;
    h1.headline {
        color: var(--front-2);
        ${P_Large_360_Bold}
    }

    .richText {
        br {
            height: 38px;
        }
    }

    .iframe-outer {
        width: 100%;
        /* the iframe should fit full size into the screen which will not work if the screen has a wider aspect ratio then the 16:9 sometimes */
        @media (min-width: 1024px) {
            margin: 0 auto;
            /* 82px nav size and 72px padding left and right + 40px buffer */
            max-width: calc(((100vh - 82px) * 1.7777777777777778) - 72px - 72px - 40px);
        }
    }
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    h2.headline--only {
        color: var(--primary-100);
        padding-bottom: 24px;
        align-self: flex-start;
        ${H2_360_Bold}
    }
`;

const Silver = css`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    & > * {
        flex: 1 1 0;
    }

    h1.headline {
        ${P_Large_768_Bold}
    }

    h2.headline--only {
        padding-bottom: 12px;
        ${H2_768_Bold}
    }
`;

const Gold = css``;

const Platinum = css`
    h1.headline {
        ${P_Large_1366_Bold}
    }

    h2.headline--only {
        ${H2_1366_Bold}
        padding-bottom: 38px;
    }
`;

const Diamond = css``;

export const STwoColTextBlockTextWrapper = styled.div`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

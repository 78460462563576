import { RichText } from "src/common";
import { IGenTwoColTextBlockComp } from "src/common/__generated/root/types";

import React, { FunctionComponent } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { STwoColTextBlock } from "./styles/STwoColTextBlock";
import { STwoColTextBlockImageWrapper } from "./styles/STwoColTextBlockImageWrapper";
import { STwoColTextBlockTextWrapper } from "./styles/STwoColTextBlockTextWrapper";
import { FancyImage } from "../../../BaseComponents/FancyImage/FancyImage";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";

const TwoColTextBlock: FunctionComponent<IGenTwoColTextBlockComp & { width?: string }> = ({
    headline,
    icon,
    text,
    width,
}) => {
    const brandAreaMaxWidth = useBrandAreaMaxWidth()

    const image = icon;

    return (
        <BrandAreaContainer>
            <STwoColTextBlock width={width} isRichtextComponent={!!text}>
                {image && image.src && (
                    <STwoColTextBlockImageWrapper>
                        <FancyImage
                            img={image}
                            responsiveAspectRatio={{
                                bronze: 468 / 312,
                                silver: 720 / 360,
                                platinum: 1214 / 608,
                            }}
                            responsiveMaxWidth={{ platinum: brandAreaMaxWidth }}
                        />
                    </STwoColTextBlockImageWrapper>
                )}
                <STwoColTextBlockTextWrapper>
                    {headline && !image && !text ? (
                        <h2 className="headline--only">{headline}</h2>
                    ) : (
                        <h1 className="headline">{headline}</h1>
                    )}
                    {text && (
                        <div className="richText">
                            <RichText fragment iframeContainer textContent={text} />
                        </div>
                    )}
                </STwoColTextBlockTextWrapper>
            </STwoColTextBlock>
        </BrandAreaContainer>
    );
};

export default TwoColTextBlock;

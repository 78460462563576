import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "src/common/constants/mediaquerys";

import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import {
  COL_AMOUNT,
  GUTTER,
  SCREEN_TYPES,
} from "../../../constantsOverwrites/grid";

const generateColums = (type): FlattenSimpleInterpolation => 
{

  const cols = "1fr "
    .repeat(COL_AMOUNT[type])
;
  return css`
    grid-gap: ${GUTTER[type]}px;
    grid-template-columns: ${cols};

  `;
};

const Default = css`
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  ${generateColums(SCREEN_TYPES.BRONZE)};
`;

const Silver = css`
  ${generateColums(SCREEN_TYPES.SILVER)};
`;

const Gold = css`
  ${generateColums(SCREEN_TYPES.GOLD)};
`;

const Platinum = css`
  ${generateColums(SCREEN_TYPES.PLATINUM)};
`;

const Diamond = css`
  ${generateColums(SCREEN_TYPES.DIAMOND)};
`;

const SRow = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SRow;

import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
`;

const Silver = css`
flex-basis: 50%;
> span > div {
    height: 100%;
}
`;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SImageTextSideBySideElementImage = styled.div<any>`
    ${Default}
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
`;

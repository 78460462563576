import styled, { css } from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";
import colors from "../../../constants/colors";
import { theme } from "../../../constants/themes";

export const SectionWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1d1d1b;
  max-width: ${theme.maxWidth}px;
  width: 100%;
  margin: auto;

  p {
    span {
      font-family: "sofia_pro_extralightregular", Helvetica, Arial, sans-serif;
      font-weight: 300;
      font-size: 15px;
      box-sizing: border-box;
    }
  }

  > * {
    width: 100%;
  }
`;

export const ContentWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  .kitchen-compass {
    display: block;
    margin-top: 60px;
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    flex-direction: row;
    /* padding: 0 60px 0; */
    img{
      max-width: 100px;
    }
    .kitchen-compass {
      display: block;
      margin-top: 60px;
      margin-bottom: -20px;
    }
  }
  @media (${BREAKPOINTS_DM.gold_1024}) {
    img{
      max-width: initial;
    }
  }
`;

export const LeftContent: any = styled.div`
  width: 100%;
  padding: 10px;
  h1 {
    color: ${colors.white};
    font-weight: 400;
    line-height: 35px;
    font-size: 22px;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 0;
    width: 75%;
    padding-right: 32px;
  }
`;

export const RightContent: any = styled.div`
  width: 100%;
  max-width: initial;

  @media (${BREAKPOINTS_DM.silver_768}) {
    width: 25%;
    margin-left: auto;
    padding: 0;
    padding-right: 33px;
  }
`;

export const mainText: any = css`
  width: 100%;
  p {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
    font-weight: normal;
    strong {
      color: #76b729;
      font-weight: 900;
    }
    em {
      font-weight: 900;
      font-style: normal;
    }
  }
`;

export const BannerHorizontalWrapper: any = styled.div`
  background-color: #1d1d1b;
  margin: 15px 0 0;
  padding: 15px 0;
  border-top: 2px dotted #fff;
  border-bottom: 2px dotted #fff;
  width: 100%;
  @media (${BREAKPOINTS_DM.silver_768}) {
    margin: 30px 30px 35px 0;
  }
`;

export const InKitchenCompass: any = styled.div`
  width: 100%;
`;

export const RichLinkWrapper: any = styled.div`
  margin-bottom: 35px;

  &:hover {
    p {
      span {
        color: #e4e4e4;
      }
    }
  }
`;

export const RichLinkAnchor: any = styled.a`
  border: 1px solid white;
  outline: double 1px white;
  display: block;

  > div {
    padding-bottom: 0;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  &:hover {
    p {
      span {
        color: #e4e4e4;
      }
    }
  }
`;

export const RichLinkImageWrapper: any = styled.div`
  border: 1px solid white;
  outline: double 1px white;

  &:hover {
    p {
      span {
        color: #e4e4e4;
      }
    }
  }
`;

export const RichLinkImage: any = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 150px;
  * {
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  h3 {
    b {
      font-size: 18px;
      font-weight: 800;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 33.33%;
    float: left;
    height: 150px;
  }

  &:before {
    z-index: 0;
    top: 0;

    content: "";
    width: 110%;
    height: 100%;
    background-image: url("${({ src }) => src}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media (${BREAKPOINTS_DM.silver_768}) {
      position: absolute;
      z-index: 0;
      top: 0;

      content: "";
      width: 100%;
      height: 100%;
      background-image: url("${({ src }) => src}");
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
    }
  }
`;

export const RichLinkImageText: any = styled.div`
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  h3 {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 21px;
    line-height: 30px;
  }
  h4 {
    font-size: 14px;
    line-height: 25px;
    clear: both;
  }
  @media (${BREAKPOINTS_DM.silver_768}) {
    float: right;
    width: 66.66%;
    padding-top: 5%;
    h3 {
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 21px;
      line-height: 30px;
    }
    h4 {
      font-size: 14px;
      line-height: 25px;
      clear: both;
    }
  }
`;

export const RichLinkContent: any = styled.div<{ src: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 20px 0 31px;
  @media (${BREAKPOINTS_DM.silver_768}) {
    height: 274px;
  }
  * {
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  }

  h3 {
    b {
      font-size: 18px;
      font-weight: 800;
    }
  }

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;

    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${({ src }) => src}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (${BREAKPOINTS_DM.gold_1024}) {
      height: 274px;
    }
  }
`;

export const RichLinkContainer = styled.div`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 60px 15px 150px;
  font-size: 13px;

  > div {
    // width: calc(100% / 12 * 6);
    padding: 0 15px;
    text-align: center;

    > h3 {
      font-size: 20px;
      line-height: 15px;
      background-color: #1d1d1b;
      padding: 15px 21px 15px 30px;
      font-weight: 100;
      @media (max-width: 460px) {
        line-height: 25px;
      }
    }

    > div {
      padding: 10px 0 30px 0;
    }

    > a {
      border-radius: 100%;
      padding: 1rem;
      background: #1d1d1b;
      color: #fff;
      font-size: 13px;
      text-transform: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 75px;
      align-items: center;
      display: flex;
      height: 75px;
      text-align: center;
      top: 140px;
      span {
        color: #fff;
        font-size: 13px;
        font-weight: 100;
      }
      span:before {
        content: "»  ";
      }
      &:hover {
        text-decoration: underline;
      }
    }
    h3:after {
      content: " ";
      display: block;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      margin-top: 14px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #1d1d1b;
    }
  }

  @media (${BREAKPOINTS_DM.silver_768}) {
    padding: 60px 15px;
  }
`;

export const RichTextWrapper: any = styled.div<{ text: boolean }>`
  width: fit-content;
  margin: ${({ text }) => (text ? "-25px auto 0" : "0 auto")};
`;

export const ProductLandingBannerWrapper: any = styled.div`
  color: #76b729;
  div.plb-c0 {
    background-color: #76b729;
  }
  h1.plb-title {
    background-color: #76b729;
  }
  div.plb-price-badge {
    background-color: #76b729;
  }

  div.plb-price-badge::before {
    background-color: #76b729;
  }
  button.plb-close {
    color: #76b729;
  }
  hr {
    border-top: 1px solid #76b729;
  }
`;

export const NewsWrapper = styled.div`
`;

export const RichLinkAnchorWrapper = styled.div`
  margin-bottom: 35px;

  @media (${BREAKPOINTS_DM.silver_768}) {
    max-height: 150px;
  }
`;

export const paddingOnMobile = css``;

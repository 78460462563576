import styled, { css } from "styled-components";

import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/mediaquerys";

const Default = css`
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--back-3);
`;

const Silver = css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 75%;
`;

const Gold = css``;

const Platinum = css`
    padding-bottom: 38px;
    gap: 32px;
`;

const Diamond = css``;

export const SBrandPageHeaderContentWrapper = styled.div<any>`
    ${Default};
    ${MIN_SILVER`${Silver}`};
    ${MIN_GOLD`${Gold}`};
    ${MIN_PLATINUM`${Platinum}`};
    ${MIN_DIAMOND`${Diamond}`};
    ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

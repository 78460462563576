import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

interface Props {
  isOpen: boolean;
}

const Default = css<Props>`
  display: block;
  position: absolute;
  background-color: var(--primary-100);
  width: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  max-height: 350px;
  overflow-y: auto;
  transition: transform 0.3s ease-in;
  transform: scaleY(0);
  transform-origin: top;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scaleY(1);
    `}

  /* not is just to overwrite with deeper nesting, better then important  */
  a:not(.x) {
    text-decoration: none;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SDropdownContent = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

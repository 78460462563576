import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";
import { CSSWithOpacityHoverAnimation } from "src/common/brand-area/constantsOverwrites/globalstyles";

const Default = css<{ isChecked: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  .checkbox {
    position: relative;
    background-color: var(--back-3);
    width: 48px;
    height: 48px;
    cursor: pointer;
    color: var(--front-3);

    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      visibility: hidden;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 50%;
      transform: translate(50%, 50%);
      transition: opacity 0.3s ease;
      opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
    }

    &-content {
      color: var(--front-2);
      font-size: 14px;
      font-weight: 400;
      line-height: 140% !important;

      a {
        color: var(--primary-100);
        text-decoration: underline;
        line-height: 140% !important;
        font-size: 14px;

        ${CSSWithOpacityHoverAnimation}
      }
    }
  }
`;

const Silver = css`
  .checkbox {
    &-content {
      &,
      a {
        font-size: 15px;
      }
    }
  }
`;

const Gold = css``;

const Platinum = css`
  gap: 19px;

  .checkbox {
    width: 76px;
    height: 76px;

    &-content {
      &,
      a {
        font-size: 21px;
      }
    }
  }
`;

const Diamond = css``;

export const SFormLabel = styled.label<{ isChecked: boolean }>`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;

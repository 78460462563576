import { Context, IPageBrandOverview } from "src/common";

import React, { useContext, Fragment, FC } from "react";

import {
    BrandAreaComponentRouter,
    IBrandAreaComponentRouterElement,
    IBrandAreaComponentRouterElementComponent,
} from "../component-router/BrandAreaComponentRouter";
import Form from "../components/form/Form";
import { BrandsDropdown } from "../components/brands-dropdown/BrandsDropdown";
import { BrandsGallery } from "../components/brands-gallery/BrandsGallery";
import SeparatorGreyLine from "../BaseComponentsOverwrites/section-separator/SeparatorGreyLine";
import { BrandAreaContainer } from "../components/brand-area-container/BrandAreaContainer";
import { SHalfWidth } from "./styles/SHalfWidth";

export const CommonPageBrandOverviewMainContent: FC<React.PropsWithChildren<unknown>> = () => {
    const c = useContext(Context);
    const page = c?.PageBrandOverview as IPageBrandOverview;

    if (!c || !page) {
        console.error(`keine "Seite - Markenübersicht" gefunden`);
        return null;
    }

    // here the dropdown is at index 1 => after the full with asset 50% next to the index at 2 (textElement)
    // at index 3 is the gallery
    // ...rest is after index 3
    const rawElements = page.elements || [];

    const startChunkFullWidth = rawElements.slice(0, 1);
    const startChunkHalfWidth = rawElements.slice(1, 2);
    const endChunk = rawElements.slice(2);

    return (
        <Fragment>
            <div className="brand-area brand-area-brandpage">
                {startChunkFullWidth && startChunkFullWidth[0] && (
                    <BrandAreaComponentRouter elements={startChunkFullWidth as IBrandAreaComponentRouterElement[]} />
                )}
                <BrandAreaContainer>
                    <SHalfWidth>
                        <div>
                            {startChunkHalfWidth && startChunkHalfWidth[0] && (
                                <BrandAreaComponentRouter
                                    elements={[
                                        {
                                            ...(startChunkHalfWidth[0] as IBrandAreaComponentRouterElementComponent),
                                            noContainer: true,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                        <BrandsDropdown />
                    </SHalfWidth>
                </BrandAreaContainer>
                <SeparatorGreyLine />
                <BrandsGallery />
                <SeparatorGreyLine />
                {endChunk && endChunk[0] && (
                    <>
                        <BrandAreaComponentRouter elements={endChunk as IBrandAreaComponentRouterElement[]} />
                        <SeparatorGreyLine />
                    </>
                )}
                <Form />
            </div>
        </Fragment>
    );
};

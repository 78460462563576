import { C_1366_Bold, C_768_Bold } from "src/common/brand-area/constantsOverwrites/typography";

import styled, { css } from "styled-components";

import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../../constants/mediaquerys";

const Default = css`
  display:none;
  
`;

const Silver = css`
display:block;
color: var(--front-2);
${C_768_Bold}
padding-top: 7px;
`;

const Gold = css``;

const Platinum = css`
${C_1366_Bold}
padding-top: 10px;
`;

const Diamond = css``;

export const SBrandName = styled.h2<any>`
  ${Default};
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;

import { css } from "styled-components";
import { useDealerSearch } from "../../../../../common/hooks/useDealerSearch";

export const DachseiteSearch = () => {
    const { triggerSearch } = useDealerSearch();

    return (
        <div>
            <div
                css={css`
                    width: 100%;
                    @media (max-width: 767px) {
                        margin-top: 20px;
                    }
                `}
            >
                <div
                    css={css`
                        position: relative;
                        text-align: center;
                        margin: 0 auto;
                        width: 100%;
                        max-width: 256px;
                    `}
                >
                    <img src="images/kw_home_search_bg.png" />
                    <div
                        css={css`
                            position: absolute;
                            width: 100%;
                            max-width: 256px;
                            top: 33%;
                            color: #f9b000;
                            font-weight: 600;
                            font-size: 20px;
                        `}
                    >
                        PLZ//STADT
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            triggerSearch((e.target as any)?.elements?.[0]?.value);
                        }}
                        css={css`
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            width: 100%;
                            max-width: 256px;
                            top: 44%;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                width: 100%;
                                max-width: 256px;
                            `}
                        >
                            <input
                                type="text"
                                placeholder=""
                                name="search"
                                css={css`
                                    height: 40px;
                                    font-size: 14px;
                                    background-color: transparent;
                                    border: none;
                                    outline: none;
                                    padding-left: 5px;
                                    color: #f9b000;
                                    text-align: center;
                                    text-overflow: ellipsis;
                                    width: 100%;
                                    overflow: hidden;
                                `}
                            />
                        </div>
                        <button
                            name="search"
                            type="submit"
                            css={css`
                                width: 80px;
                                margin: auto;
                                height: 30px;
                                opacity: 0.6;
                                border: none;
                                font-size: 20px;
                                color: #f9b000;
                            `}
                        >
                            SUCHE
                        </button>
                    </form>
                </div>
            </div>
            <div>
                <h3
                    css={css`
                        text-transform: uppercase;
                        padding: 20px 25%;
                        color: #f9b000;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 600;
                        text-align: center;
                        @-moz-document url-prefix() {
                            font-size: 18px;
                        }
                    `}
                >
                    Finden Sie die Küchen&shy;werk&shy;statt in Ihrer Nähe{" "}
                </h3>
            </div>
        </div>
    );
};

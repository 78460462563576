import { IGenImageTextSideBySideElementComp } from "src/common/__generated/root/types";
import { RichText } from "src/common/components/RichText";
import React, { FunctionComponent, createRef, useEffect, useState } from "react";

import { BrandAreaContainer } from "../brand-area-container/BrandAreaContainer";
import { SImageTextSideBySideElement } from "./styles/SImageTextSideBySideElement";
import { SImageTextSideBySideElementImage } from "./styles/SImageTextSideBySideElementImage";
import { SImageTextSideBySideElementText } from "./styles/SImageTextSideBySideElementText";
import { FancyImageWithModal } from "../../../BaseComponents/FancyImageWithModal/FancyImageWithModal";
import { debounce } from "src/common";
import { useBrandAreaMaxWidth } from "../../hooks/useBrandAreaMaxWidth";

export const ImageTextSideBySideElement: FunctionComponent<IGenImageTextSideBySideElementComp> = ({
    image,
    isTextOnLeft,
    text,
}) => {
    const brandAreaMaxWidth = useBrandAreaMaxWidth()
    const aspectRatioBronze = (image?.width || 1) / (image?.height || 1);
    const [imageRenderedHeight, setImageRenderedHeight] = useState<number>(0);

    const imageSideRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (typeof window !== "undefined" && imageSideRef.current) {
            const imageByClassName = imageSideRef.current && imageSideRef.current.childNodes && imageSideRef.current.childNodes[0] as HTMLElement; 

            const getImageRenderedWidth = () => {
                // @ts-ignore
                const clientHeight = imageByClassName?.clientHeight;
                clientHeight && setImageRenderedHeight(clientHeight);
            };

            getImageRenderedWidth();

            const debouncedImageRenderedWidth = debounce({ callBack: getImageRenderedWidth, delay: 300 });

            window.addEventListener("resize", debouncedImageRenderedWidth);

            return () => {
                window.removeEventListener("resize", debouncedImageRenderedWidth);
            };
        }
    }, [image, imageSideRef.current]);


    return (
        image && (
            <BrandAreaContainer>
                <SImageTextSideBySideElement>
                    {image?.src && (
                        <SImageTextSideBySideElementImage ref={imageSideRef}>
                            <FancyImageWithModal
                                key={image.id}
                                img={image}
                                responsiveAspectRatio={{
                                    // use for all images the same aspect ratio
                                    bronze: aspectRatioBronze,
                                    silver: 360 / 270,
                                    platinum: 607 / 455,
                                }}
                                responsiveFactor={{ silver: 0.5 }}
                                responsiveMaxWidth={{ platinum: brandAreaMaxWidth * 0.5 }}
                            />
                        </SImageTextSideBySideElementImage>
                    )}
                    {text && (
                        <SImageTextSideBySideElementText
                            isTextOnLeft={isTextOnLeft}
                            className="richText"
                            imageRenderedHeight={imageRenderedHeight}
                        >
                            <RichText fragment textContent={text} />
                        </SImageTextSideBySideElementText>
                    )}
                </SImageTextSideBySideElement>
            </BrandAreaContainer>
        )
    );
};
